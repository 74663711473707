import { BaseModel, Parse } from './base/index';
import { PlatformEnum, ChangeLogTypeEnum } from './transient/changelog.enums';

export class ChangeLog extends BaseModel {
    public static PARSE_CLASSNAME = 'ChangeLog';

    private _title: string;
    private _version: string;
    private _changes: string;
    private _image: Parse.File;
    private _platform: PlatformEnum;
    private _changeType: ChangeLogTypeEnum;

    constructor() {
        super(ChangeLog.PARSE_CLASSNAME);
    }

    public get title(): string {
        return this._title;
    }

    public set title(value: string) {
        this._title = value;
    }

    public get version(): string {
        return this._version;
    }

    public set version(value: string) {
        this._version = value;
    }

    public get changes(): string {
        return this._changes;
    }

    public set changes(value: string) {
        this._changes = value;
    }

    public get image(): Parse.File {
        return this._image;
    }

    public set image(value: Parse.File) {
        this._image = value;
    }

    public get platform(): PlatformEnum {
        return this._platform;
    }

    public set platform(value: PlatformEnum) {
        this._platform = value;
    }

    public get changeType(): ChangeLogTypeEnum {
        return this._changeType;
    }

    public set changeType(value: ChangeLogTypeEnum) {
        this._changeType = value;
    }


}

BaseModel.registerClass(ChangeLog, ChangeLog.PARSE_CLASSNAME);
