import { Injectable } from '@angular/core';
import { ErrorService, ParseService, Parse } from '../services/index';
import { Jobs, PageInfo } from '../models/index';
import { BaseModelService } from './base/base-modelservice';

@Injectable()
export class JobService extends BaseModelService<Jobs> {

    constructor(errorService: ErrorService, parseService: ParseService) {
        super(errorService, parseService, Jobs);
    }

    public getByFilter(searchQuery?: string, pageInfo?: PageInfo): Promise<Array<Jobs>> {
        return new Promise<Array<Jobs>>((resolve, reject) => {
            let query = this.createQuery();
            if (pageInfo) {
                pageInfo.filterQuery = searchQuery;
                query = this.applyPageInfo(query, pageInfo, this.filterByAttributes);
            } else {
                query = this.applyFilter(query, searchQuery, this.filterByAttributes);
            }
            query.find().then(jobs => resolve(jobs), error => this.errorService.handleParseErrors(error));
        });
    }

    private filterByAttributes(query: Parse.Query<Jobs>, filterQuery: string): Parse.Query<Jobs> {
        const queries = new Array<Parse.Query<Jobs>>();
        ['name', 'description'].forEach((attribute) => {
            const orQuery = new Parse.Query(Jobs);
            orQuery.matches(attribute, new RegExp(filterQuery), 'i');
            queries.push(orQuery);
        });
        return Parse.Query.or<Jobs>(...queries);
    }

    public getAll(): Promise<Array<Jobs>> {
        return new Promise<Array<Jobs>>((resolve, reject) => {
            const query = this.createQuery();
            query.equalTo('activated', true);
            query.find().then(jobsList => resolve(jobsList), error => this.errorService.handleParseErrors(error));
        });
    }
}
