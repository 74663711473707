import { Injectable } from '@angular/core';
import { ErrorService, ParseService, Parse } from '../services/index';
import { BaseModelService } from './base/base-modelservice';
import { ChangeLog } from '../models/change-log';
import { PageInfo } from '../models/index';

@Injectable()
export class ChangeLogService extends BaseModelService<ChangeLog> {

    constructor(errorService: ErrorService, parseService: ParseService) {
        super(errorService, parseService, ChangeLog);
    }

    public getByFilter(searchQuery?: string, pageInfo?: PageInfo): Promise<Array<ChangeLog>> {
        return new Promise<Array<ChangeLog>>((resolve, reject) => {
            let query = this.createQuery();

            if (pageInfo) {
                pageInfo.filterQuery = searchQuery;
                query = this.applyPageInfo(query, pageInfo, this.filterByAttributes);
            } else {
                query = this.applyFilter(query, searchQuery, this.filterByAttributes);
            }

            query.find().then(changeLogs => resolve(changeLogs), error => this.errorService.handleParseErrors(error));
        });
    }

    private filterByAttributes(query: Parse.Query<ChangeLog>, filterQuery: string): Parse.Query<ChangeLog> {
        const queries = new Array<Parse.Query<ChangeLog>>();
        ['title', 'changes', 'platform'].forEach((attribute) => {
            const orQuery = new Parse.Query(ChangeLog);
            orQuery.matches(attribute, new RegExp(filterQuery), 'i');
            queries.push(orQuery);
        });
        return Parse.Query.or<ChangeLog>(...queries);
    }
}
