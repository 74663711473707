import { Injectable } from '@angular/core';
import { ErrorService, ParseService, Parse } from '../services/index';
import { Role, User, ControlCenter } from '../models/index';
import { BaseModelService } from './base/base-modelservice';

@Injectable()
export class ControlCenterService extends BaseModelService<ControlCenter>  {

    constructor(protected errorService: ErrorService, protected parseService: ParseService) {
        super(errorService, parseService, ControlCenter);
    }
}
