import { Injectable } from '@angular/core';
import * as Parse from 'parse'
import { ActivatedRouteSnapshot, Router } from '@angular/router'
import { RolePrivilegeEnum, RoleRestrictionEnum } from 'app/data/common/models/transient/role.enums';
import { ToastrService } from 'ngx-toastr';
import { CookieService } from 'ngx-cookie-service';
import { ParseService } from 'app/data/common/services/parse.service';
import { User } from 'app/data/common/models/user';
import { Role } from 'app/data/common/models';
import { ControlCenterService, RoleService } from 'app/data/modelservices';

@Injectable()
export default class AuthenticationService  {

    private mainRole: Role = null;
    private roles = new Set<Role>();
    private privileges = new Set<string>();
    private restrictions = new Set<string>();
    private isSU: boolean;

    constructor(private router: Router, private parseService: ParseService, private roleService: RoleService, private toasterService: ToastrService, private cookieService: CookieService, private controlCenterService: ControlCenterService) {
    }

    public initialize() {
        return new Promise<void>((resolve, reject) => {
            this.roles.clear();
            this.privileges.clear();
            this.restrictions.clear();
            this.isSU = false;
            this.mainRole = null;

            if (this.isAuthenticated()) {
                this.roleService.getUserRoles(this.getAuthenticatedUser()).then((roles) => {
                    this.mainRole = ((roles.length > 0) ? roles[0] : null);
                    for (const role of roles) {
                        this.roles.add(role);
                        if (role.privileges) {
                            for (const privilege of role.privileges) {
                                this.privileges.add(privilege)
                            }
                        }
                        if (role.restrictions) {
                            for (const restriction of role.restrictions) {
                                this.restrictions.add(restriction)
                            }
                        }
                    }
                    this.isSU = this.hasPrivilege(RolePrivilegeEnum.su_any);

                    if (this.hasPrivilege(RolePrivilegeEnum.su_CCSwitch) && this.cookieService.get(RolePrivilegeEnum.su_CCSwitch) !== undefined) {
                        this.controlCenterService.getById(this.cookieService.get(RolePrivilegeEnum.su_CCSwitch)).then(function (controlCenter) {
                            Parse.User.current().set('controlCenterRelation', controlCenter);
                            resolve();
                        })
                    } else {
                        if (!Parse.User.current().get('controlCenterRelation')) {
                            this.logout();
                            this.toasterService.error('Sie sind keiner Leitstelle zugeordnet!', 'Zugriff verweigert');
                            reject();
                        } else {
                            resolve();
                        }
                    }
                })
            } else {
                resolve();
            }
        })
    }

    public canActivateChild(route: ActivatedRouteSnapshot): boolean {
        const requiresAuthentication: boolean = !(route.data.requiresAuthentication === false);
        if (requiresAuthentication && !this.isAuthenticated()) {
            this.router.navigate(['/pages/login']);
            return false;
        }
        const requiresPrivilieges = route.data.requiresPrivileges;
        if (requiresPrivilieges !== undefined && requiresPrivilieges instanceof Array) {
            for (const requiredPrivilege of requiresPrivilieges) {
                if (!this.hasPrivilege(requiredPrivilege)) {
                    this.toasterService.error('Sie verfügen nicht über die notwendigen Rechte, um diese Inhalte aufzurufen.', 'Zugriff verweigert');
                    this.router.navigate(['/pages/login']);
                    return false;
                }
            }
        }
        return true;
    }

    public login(username, password) {
        return new Promise<User>(async (resolve, reject) => {
            try {
                const user = await Parse.User.logIn(username, password);
                if (user) {
                    this.initialize().then(() => {
                        if (this.privileges.size === 0) {
                            reject('Sie verfügen nicht über die notwendigen Rechte zur Anmeldung.')
                        } else {
                            resolve(this.getAuthenticatedUser())
                        }
                    });
                } else {
                    reject('Benutzername oder Passwort sind falsch!')
                }
            } catch (error) {
                reject(error);
            }
            
        })
    }

    public logout() {
        Parse.User.logOut().then(() => this.initialize());
    }

    public resetPassword(username): Promise<void> {
        return new Promise<void>(async (resolve, reject) => {
            try {
                await Parse.Cloud.run('resetUserPassword', { email: username });
                resolve();
            } catch (error) {
                reject(error);
            }
        })
    }

    public hasPrivilege(privilege: RolePrivilegeEnum): boolean {
        return this.isSU ? true : this.privileges.has(privilege)
    }

    public hasRestriction(restriction: RoleRestrictionEnum): boolean {
        return this.restrictions.has(restriction);
    }

    public getOriginRestriction(): string {
        for (const restriction of Array.from(this.restrictions.values())) {
            if (restriction.startsWith('origin')) {
                return restriction.replace('origin.', '');
            }
        }
        return null;
    }

    public getMainRole(): Role {
        return this.mainRole;
    }

    public getAuthenticatedUser(): User {
        return this.parseService.patchSubclass(Parse.User.current()) as User;
    }

    public isAuthenticated(): boolean {
        return this.getAuthenticatedUser() !== null;
    }
}
