import { Injectable } from '@angular/core';
import { ErrorService, ParseService, Parse } from '../services/index';
import { Certificate } from '../models/index';
import { BaseModelService } from './base/base-modelservice';

@Injectable()
export class CertificateService extends BaseModelService<Certificate> {

    constructor(errorService: ErrorService, parseService: ParseService) {
        super(errorService, parseService, Certificate);
    }

    public getMain(): Promise<Certificate[]> {
        return new Promise<Certificate[]>((resolve, reject) => {
            const query = this.createQuery();
            query.equalTo('type', 1);
            query.find().then(certificates => resolve(certificates), error => this.errorService.handleParseErrors(error));
        });
    }

    public getOptional(): Promise<Certificate[]> {
        return new Promise<Certificate[]>((resolve, reject) => {
            const query = this.createQuery();
            query.notEqualTo('type', 1);
            query.find().then(certificates => resolve(certificates), error => this.errorService.handleParseErrors(error));
        });
    }

    public getById(id: string): Promise<Certificate> {
        return new Promise<Certificate>((resolve, reject) => {
            const query = this.createQuery();
            query.equalTo('objectId', id);
            query.first().then(certificate => resolve(certificate), error => this.errorService.handleParseErrors(error));
        });
    }
}