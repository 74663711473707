import { BaseModel } from './base/index';

export class Files extends BaseModel {
  public static PARSE_CLASSNAME = 'Files';

  private _title: string;
  private _short_name: string;
  private _file: Parse.File;

  constructor() {
    super(Files.PARSE_CLASSNAME);
  }

  get title(): string {
    return this._title;
  }

  set title(value: string) {
    this._title = value;
  }

  get short_name(): string {
    return this._short_name;
  }

  set short_name(value: string) {
    this._short_name = value;
  }

  get file(): Parse.File {
    return this._file;
  }

  set file(value: Parse.File) {
    this._file = value;
  }
}

BaseModel.registerClass(Files, Files.PARSE_CLASSNAME);
