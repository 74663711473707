import { Injectable } from '@angular/core';
import { EmergencyState, PageInfo, Tracer, User } from '../models/index';
import { ErrorService, ParseService, Parse } from '../services/index';
import { BaseModelService } from './base/base-modelservice';

@Injectable()
export class TracerService extends BaseModelService<Tracer> {

    constructor(errorService: ErrorService, parseService: ParseService) {
        super(errorService, parseService, Tracer);
    }

    public getByUsers(user: User, searchQuery?: string, pageInfo?: PageInfo): Promise<Array<Tracer>> {
        return new Promise<Array<Tracer>>((resolve, reject) => {
            let query = this.createQuery();
            if (pageInfo) {
                pageInfo.filterQuery = searchQuery;
                query = this.applyPageInfo(query, pageInfo, this.filterByAttributes);
            } else {
                query = this.applyFilter(query, searchQuery, this.filterByAttributes);
            }
            query.equalTo('user', user);
            query.find().then(tracers => resolve(tracers), error => this.errorService.handleParseErrors(error));
        });
    }

    public getByEmergencyState(emergencyState: EmergencyState, searchQuery?: string, pageInfo?: PageInfo): Promise<Array<Tracer>> {
        return new Promise<Array<Tracer>>((resolve, reject) => {
            let query = this.createQuery();
            query.equalTo('emergencyState', emergencyState);
            if (pageInfo) {
                pageInfo.filterQuery = searchQuery;
                query = this.applyPageInfo(query, pageInfo, this.filterByAttributes);
            } else {
                query = this.applyFilter(query, searchQuery, this.filterByAttributes);
            }
            query.find().then(tracers => resolve(tracers), error => this.errorService.handleParseErrors(error));
        });
    }

    private filterByAttributes(query: Parse.Query<Tracer>, filterQuery: string): Parse.Query<Tracer> {
        const queries = new Array<Parse.Query<Tracer>>();
        ['type', 'message'].forEach((attribute) => {
            const orQuery = new Parse.Query(Tracer);
            orQuery.matches(attribute, new RegExp(filterQuery), 'i');
            queries.push(orQuery);
        });
        return Parse.Query.or<Tracer>(...queries);
    }
}
