import { Injectable } from '@angular/core';
import { ErrorService, ParseService } from '../services/index';
import { AlertingRegion, Configuration, ControlCenter, Emergency } from '../models/index';
import { ServiceManager } from '../services/service-manager';
import { BaseModelService } from './base/base-modelservice';
import { AlertingRegionService } from './alerting-region.service';

@Injectable()
export class ConfigurationService extends BaseModelService<Configuration> {

    constructor(protected errorService: ErrorService, protected parseService: ParseService) {
      super(errorService, parseService, Configuration);
    }

    /* public getByControlCenter(controlCenter: ControlCenter): Promise<Configuration> {
        return new Promise<Configuration>((resolve, reject) => {
            const query = this.createQuery();
            query.equalTo('controlCenterRelation', controlCenter);
            query.first().then(configuration => {resolve(configuration);
            }, error => this.errorService.handleParseErrors(error));
        });
    } */

    public async getByControlCenter(controlCenter: ControlCenter): Promise<Configuration> {
            const query = this.createQuery();
            query.equalTo('controlCenterRelation', controlCenter);
            try {
                const configuration = await query.first();
                return configuration;
            } catch (error) {
                throw error;
            }
    }

    public getDefault(controlCenter: ControlCenter): Configuration {
        const configuration = new Configuration();
        configuration.distance = 1000;
        configuration.controlCenterRelation = controlCenter;
        return configuration;
    }

    public async getByEmergency(emergency: Emergency): Promise<Configuration> {
        return new Promise<Configuration>(async(resolve, reject) => {
            try {
                let configuration = await this.getByControlCenter(emergency.controlCenterRelation);
                if (!configuration) {
                    configuration = this.getDefault(emergency.controlCenterRelation);
                }

                const alertingRegionService = ServiceManager.get(AlertingRegionService);
                const query = alertingRegionService.createQuery();
                query.equalTo('controlCenterRelation', emergency.controlCenterRelation);
                query.equalTo('activated', true);
                const regions = await query.find() as unknown as {results: Array<AlertingRegion>, count: Number};
                if (regions.count > 0) {
                    for (const region of regions.results) {
                        const distance = region.location.kilometersTo(emergency.locationPoint) * 1000;
                        if (distance <= region.circleRadius && region.alertingRadius < configuration.distance) {
                            configuration.distance = region.alertingRadius;
                        }
                    }
                }
                resolve(configuration);
                /* query.find().then(regions => {
                    for (const region of regions) {
                        const distance = region.location.kilometersTo(emergency.locationPoint) * 1000;
                        if (distance <= region.circleRadius && region.alertingRadius < configuration.distance) {
                            configuration.distance = region.alertingRadius;
                        }
                    }
                    resolve(configuration);
                }, error => this.errorService.handleParseErrors(error)); */
            } catch (e) {
                reject(e);
            }
        });
    }
}
