import { RolePrivilegeEnum } from 'app/data/common/models/transient/role.enums';

export const navigation = [
    {
        title: true,
        name: 'Dashboards',
        requiresPrivileges: [RolePrivilegeEnum.route_dashboard]
    },
    {
        name: 'Admin Dashboard',
        url: '/app/dashboard/admin',
        icon: 'fa fa-line-chart',
        requiresPrivileges: [RolePrivilegeEnum.route_adminDashboard]
    },
    {
        name: 'Diagramme',
        url: '/app/dashboard/control-center',
        icon: 'fa fa-bar-chart',
        requiresPrivileges: [RolePrivilegeEnum.route_dashboard]
    },
    {
        name: 'Notfalltabelle',
        url: '/app/dashboard/emergency-table',
        icon: 'fa fa-table',
        requiresPrivileges: [RolePrivilegeEnum.route_dashboard]
    },
    {
        name: 'Notfallkarte',
        url: '/app/dashboard/emergency-map',
        icon: 'fa fa-map',
        requiresPrivileges: [RolePrivilegeEnum.route_dashboard]
    },
    {
        title: true,
        name: 'Notfälle'
    },
    {
        name: 'Versendete Alarme',
        url: '/app/emergency/all',
        icon: 'fa fa-ambulance',
        requiresPrivileges: [RolePrivilegeEnum.route_emergencyAdmin],
        badge: {
            variant: 'primary',
            special: 'emergencies-total'
        }
    },
    {
        name: 'Aktive Notfälle',
        url: '/app/emergency/active',
        icon: 'fa fa-ambulance',
        requiresPrivileges: [RolePrivilegeEnum.route_emergencyMap],
        badge: {
            variant: 'danger',
            special: 'active-emergencies'
        }
    },
    {
        name: 'Notfallhistorie',
        url: '/app/emergency/protocol',
        icon: 'fa fa-history',
        requiresPrivileges: [RolePrivilegeEnum.route_emergencyList],
        badge: {
            variant: 'primary',
            special: 'open-protocols'
        }
    },
    {
        name: 'Notfall versenden',
        url: '/app/emergency/create',
        icon: 'fa fa-bell',
        requiresPrivileges: [RolePrivilegeEnum.route_createEmergency],
        children: [
            {
                name: 'Ersthelfer',
                url: '/app/emergency/create',
                icon: 'fa fa-medkit',
                requiresPrivileges: [RolePrivilegeEnum.route_createEmergency],
            },
            {
                name: 'Spontanhelfer',
                url: '/app/spontaneousHelper/list',
                icon: 'fa fa-life-ring',
                requiresPrivileges: [RolePrivilegeEnum.route_spontaneousHelper],
            }]
    },
    {
        title: true,
        name: 'Inhalte'
    },
    {
        name: 'Ersthelfer',
        icon: 'fa fa-group',
        url: '/app/user/all',
        badge: {
            variant: 'primary',
            special: 'all-users'
        },
        badgeSecond: {
            variant: 'danger',
            special: 'unchecked-users'
        },
        requiresPrivileges: [RolePrivilegeEnum.route_user],
        /* children: [
            {
                name: 'Alle',
                url: '/app/user/all',
                icon: 'fa fa-medkit',
                badge: {
                    variant: 'primary',
                    special: 'users'
                },
                requiresPrivileges: [RolePrivilegeEnum.route_user]
            },
            {
                name: 'Ungeprüft',
                url: '/app/user/unchecked',
                icon: 'fa fa-life-ring',
                badge: {
                    variant: 'danger',
                    special: 'unchecked-users'
                },
                requiresPrivileges: [RolePrivilegeEnum.route_user]
            }] */
    },
/*     {
        name: 'Email Verwaltung',
        url: '/app/emailManagement',
        icon: 'fa fa-mail',
        requiresPrivileges: [RolePrivilegeEnum.route_emailManagement]
    }, */
/*     {
        name: 'Ersthelfer',
        url: '/app/ccfirstresponder',
        icon: 'fa fa-group',
        requiresPrivileges: [RolePrivilegeEnum.route_ccfirstresponder]
    }, */
    {
        name: 'Defibrillatoren',
        url: '/app/defibrillator/map',
        icon: 'fa fa-heartbeat',
        badge: {
            variant: 'primary',
            special: 'defibrillators'
        },
        requiresPrivileges: [RolePrivilegeEnum.route_defibrillator],
        children: [
            {
                name: 'Verwaltung',
                url: '/app/defibrillator/list',
                icon: 'fa fa-list',
                requiresPrivileges: [RolePrivilegeEnum.route_defibrillatorManagement]
            },
            {
                name: 'Karte',
                url: '/app/defibrillator/map',
                icon: 'fa fa-map',
                requiresPrivileges: [RolePrivilegeEnum.route_defibrillator]
            }
            ]
    },
    {
        name: 'Social',
        url: '/app/social',
        icon: 'fa fa-newspaper-o',
        requiresPrivileges: [RolePrivilegeEnum.route_social]
    },
/*     {
        name: 'Kursverwaltung',
        url: '/app/course',
        icon: 'fa fa-user-md',
        requiresPrivileges: [RolePrivilegeEnum.route_social]
    }, */
    {
        name: 'Hilfe',
        url: 'https://www.savinglife.de/',
        icon: 'fa fa-question-circle'
    },
    {
        title: true,
        name: 'Einstellungen'
    },
    {
        name: 'Alarmierungsparameter',
        url: '/app/region/list',
        icon: 'fa fa-cogs',
        requiresPrivileges: [RolePrivilegeEnum.route_region]
    },
    {
        name: 'Batch Job Verwaltung',
        url: '/app/jobManagement',
        icon: 'fa fa-cubes',
        requiresPrivileges: [RolePrivilegeEnum.route_user]
    }
 ];
 /*   {
        title: true,
        name: '#### DEMO STUFF ####'
    },

    {
        title: true,
        name: 'UI elements'
    },
    {
        name: 'Components',
        url: '/app/components',
        icon: 'icon-puzzle',
        children: [
            {
                name: 'Buttons',
                url: '/app/components/buttons',
                icon: 'icon-puzzle'
            },
            {
                name: 'Social Buttons',
                url: '/app/components/social-buttons',
                icon: 'icon-puzzle'
            },
            {
                name: 'Cards',
                url: '/app/components/cards',
                icon: 'icon-puzzle'
            },
            {
                name: 'Modals',
                url: '/app/components/modals',
                icon: 'icon-puzzle'
            },
            {
                name: 'Switches',
                url: '/app/components/switches',
                icon: 'icon-puzzle'
            },
            {
                name: 'Tables',
                url: '/app/components/tables',
                icon: 'icon-puzzle'
            },
            {
                name: 'Tabs',
                url: '/app/components/tabs',
                icon: 'icon-puzzle'
            }
        ]
    },
    {
        name: 'Icons',
        url: '/app/icons',
        icon: 'icon-star',
        children: [
            {
                name: 'Font Awesome',
                url: '/app/icons/font-awesome',
                icon: 'icon-star',
                badge: {
                    variant: 'secondary',
                    text: '4.7'
                }
            },
            {
                name: 'Simple Line Icons',
                url: '/app/icons/simple-line-icons',
                icon: 'icon-star'
            }
        ]
    },
    {
        name: 'Forms',
        url: '/app/forms',
        icon: 'icon-note',
        children: [
            {
                name: 'Basic Forms',
                url: '/app/forms/basic-forms',
                icon: 'icon-note'
            },
            {
                name: 'Advanced Forms',
                url: '/app/forms/advanced-forms',
                icon: 'icon-note'
            },
        ]
    },
    {
        name: 'Editors',
        url: '/app/editors',
        icon: 'fa fa-code',
        children: [
            {
                name: 'Text Editors',
                url: '/app/editors/text-editors',
                icon: 'icon-note'
            },
            {
                name: 'Code Editors',
                url: '/app/editors/code-editors',
                icon: 'fa fa-code'
            }
        ]
    },
    {
        name: 'Plugins',
        url: '/app/plugins',
        icon: 'icon-energy',
        children: [
            {
                name: 'Calendar',
                url: '/app/plugins/calendar',
                icon: 'icon-calendar'
            },
            {
                name: 'DataTable',
                url: '/app/plugins/datatable',
                icon: 'icon-menu'
            },
            {
                name: 'Draggable Cards',
                url: '/app/plugins/draggable-cards',
                icon: 'icon-cursor-move'
            },
            {
                name: 'Loading Buttons',
                url: '/app/plugins/loading-buttons',
                icon: 'icon-cursor'
            },
            {
                name: 'Notifications',
                url: '/app/plugins/notifications',
                icon: 'icon-info'
            },
            {
                name: 'Spinners',
                url: '/app/plugins/spinners',
                icon: 'fa fa-spinner'
            }
        ]
    },
    {
        name: 'Widgets',
        url: '/app/widgets',
        icon: 'icon-calculator',
        badge: {
            variant: 'info',
            text: 'NEW'
        }
    },
    {
        name: 'Charts',
        url: '/app/charts',
        icon: 'icon-pie-chart'
    },
    {
        name: 'Google Maps',
        url: '/app/google-maps',
        icon: 'icon-map',
        badge: {
            variant: 'info',
            text: 'NEW'
        }
    },
    {
        divider: true
    },
    {
        title: true,
        name: 'Extras',
    },
    {
        name: 'Pages',
        url: '/app/pages',
        icon: 'icon-star',
        children: [
            {
                name: 'Login',
                url: '/app/pages/login',
                icon: 'icon-star'
            },
            {
                name: 'Register',
                url: '/app/pages/register',
                icon: 'icon-star'
            },
            {
                name: 'Error 404',
                url: '/app/pages/404',
                icon: 'icon-star'
            },
            {
                name: 'Error 500',
                url: '/app/pages/500',
                icon: 'icon-star'
            }
        ]
    },
    {
        name: 'UI Kits',
        url: '/app/uikits',
        icon: 'icon-layers',
        children: [
            {
                name: 'Invoicing',
                url: '/app/uikits/invoicing',
                icon: 'icon-speech',
                children: [
                    {
                        name: 'Invoice',
                        url: '/app/uikits/invoicing/invoice',
                        icon: 'icon-speech'
                    }
                ]
            },
            {
                name: 'Email',
                url: '/app/uikits/email',
                icon: 'icon-speech',
                children: [
                    {
                        name: 'Inbox',
                        url: '/app/uikits/email/inbox',
                        icon: 'icon-speech'
                    },
                    {
                        name: 'Message',
                        url: '/app/uikits/email/message',
                        icon: 'icon-speech'
                    },
                    {
                        name: 'Compose',
                        url: '/app/uikits/email/compose',
                        icon: 'icon-speech'
                    }
                ]
            }
        ]
    }
];*/
