import { Injectable } from '@angular/core';
import { ErrorService, ParseService, Parse, Subscription } from '../services/index';
import { Certificate, ControlCenter, Installation, PageInfo, Role, User, UserApprovalStateEnum } from '../models/index';
import { BaseModelService } from './base/base-modelservice';
import { RoleService } from './role.service';
import { ServiceManager } from '../services/service-manager';

@Injectable()
export class UserService extends BaseModelService<User> {
    private roleService = ServiceManager.get(RoleService);

    constructor(errorService: ErrorService, parseService: ParseService) {
        super(errorService, parseService, User);
    }
    public async getById(userId: string): Promise<User> {
        return new Promise<User>((resolve, reject) => {
            const query = this.createQuery();
            query.equalTo('objectId', userId);
            query.include('controlCenterRelation');
            query.include('certificateFR');
            query.include('userContractBasic');
            query.first().then(user => resolve(user), error => this.errorService.handleParseErrors(error));
        });
    }

    // @ts-ignore (todo: rename)
    public getByFilter(searchQuery?: string, pageInfo?: PageInfo, adultOnly: Boolean = false, uncheckedOnly: Boolean = false, origin?: string): Promise<Array<User>> {
        return new Promise<Array<User>>((resolve, reject) => {
            let query = this.createQuery();
            if (uncheckedOnly) {
                this.limitQueryToUncheckedUsers(query);
            }

            if (adultOnly) {
                this.limitQueryToAdultUsers(query);
            }

            if (pageInfo) {
                pageInfo.filterQuery = searchQuery;
                query = this.applyPageInfo(query, pageInfo, this.filterByAttributes);
            } else {
                query = this.applyFilter(query, searchQuery, this.filterByAttributes);
            }
            if (origin) {
                query.equalTo('origin', origin);
            }
            query.include('certificateFR');
            query.include('certificates');
            query.include('userContractBasic');
            query.doesNotExist('authData.anonymous.id');
            query.find().then(firstresponders => resolve(firstresponders), error => this.errorService.handleParseErrors(error));
        });
    }

    public installationsByUsername(username: String): Promise<Array<Installation>> {
        return new Promise<Array<Installation>>((resolve, reject) => {
            
        });
    }

    public getUncheckedByFilter(searchQuery?: string, pageInfo?: PageInfo, origin?: string): Promise<Array<User>> {
        return new Promise<Array<User>>((resolve, reject) => {
            let query = this.createQuery();

            if (pageInfo) {
                pageInfo.filterQuery = searchQuery;
                query = this.applyPageInfo(query, pageInfo, this.filterByAttributes);
            } else {
                query = this.applyFilter(query, searchQuery, this.filterByAttributes);
            }
            if (origin) {
                query.equalTo('origin', origin);
            }
            query.include('certificateFR');
            query.include('certificates');
            query.include('userContractBasic');
            query.equalTo('approvalState', 1);
            query.notEqualTo('activated', true);
            query.equalTo('emailVerified', true);
            query.doesNotExist('authData.anonymous.id');
            query.find().then(firstresponders => resolve(firstresponders), error => this.errorService.handleParseErrors(error));
        });
    }

    public countUnchecked(): Promise<number> {
        return new Promise<number>((resolve, reject) => {
            let query = this.createQuery();
            query.equalTo('approvalState', 1);
            query.notEqualTo('activated', true);
            query.equalTo('emailVerified', true);
            query.limit(9999999);
            query.doesNotExist('authData.anonymous.id');
            query.count().then(firstresponders => resolve(firstresponders), error => this.errorService.handleParseErrors(error));
        });
    }

    private filterByAttributes(query: Parse.Query<User>, filterQuery: string): Parse.Query<User> {
        const queries = new Array<Parse.Query<User>>();
        ['firstname', 'lastname', 'username', 'city', 'county'].forEach((attribute) => {
            const orQuery = new Parse.Query(User);
            orQuery.matches(attribute, new RegExp(filterQuery), 'i');
            queries.push(orQuery);
        });
        return Parse.Query.or<User>(...queries);
    }

    public getAllDisponentsByControlCenterId(controlCenter: ControlCenter): Promise<Array<User>> {
        return new Promise<Array<User>>(async (resolve, reject) => {
            const query = this.createQuery();
            try {
                const disponents = await this.roleService.getUsersByRole('Disponent') as unknown as {results: Array<User>, count: number};
                const returnArray = new Array<User>();
                if (disponents.count > 0) {
                    for (const disponent of disponents.results) {
                        if (disponent.controlCenterRelation && disponent.controlCenterRelation.id === controlCenter.id) {
                            returnArray.push(disponent);
                        }
                    }
                }
                resolve(returnArray);
            } catch (error) {
                this.errorService.handleParseErrors(error);
                reject(error);
            }
        });
    }

    public getAll(): Promise<Array<User>> {
        return new Promise<Array<User>>((resolve, reject) => {
            const query = this.createQuery();
            query.find().then(firstresponders => resolve(firstresponders), error => this.errorService.handleParseErrors(error));
        });
    }

    public sub(user: User): Promise<Subscription<User>> {
        return new Promise<Subscription<User>>((resolve, reject) => {
            const query = this.createQuery();
            query.equalTo('objectId', user.id);
            query.include('controlCenterRelation');
            this.parseService.subscribe<User>(query).then(subscription => resolve(subscription));
        });
    }

    public subById(userId: string): Promise<Subscription<User>> {
        return new Promise<Subscription<User>>((resolve, reject) => {
            const query = this.createQuery();
            query.equalTo('objectId', userId);
            query.include('controlCenterRelation');
            this.parseService.subscribe<User>(query).then(subscription => resolve(subscription));
        });
    }

    public subByUsername(username: string): Promise<Subscription<User>> {
        return new Promise<Subscription<User>>((resolve, reject) => {
            const query = this.createQuery();
            query.equalTo('username', username);
            query.limit(20);
            this.parseService.subscribe<User>(query).then(subscription => resolve(subscription));
        });
    }

    public startsWithUsername(username: string): Promise<Array<User>> {
        return new Promise<Array<User>>((resolve, reject) => {
            const query = this.createQuery();
            query.startsWith('username', username);
            query.limit(20);
            query.find().then(firstresponders => resolve(firstresponders), error => this.errorService.handleParseErrors(error));
        });
    }

    public getQuery(): Parse.Query<User> {
        return this.createQuery();
    }

    public limitQueryToUncheckedUsers(query: Parse.Query) {

        query.notEqualTo('activated', true);
        query.equalTo('emailVerified', true);
        query.equalTo('approvalState', UserApprovalStateEnum.validationSuccess);
    }

    public limitQueryToAdultUsers(query: Parse.Query) {

        let eighteenYearsAgo = new Date();
        eighteenYearsAgo = new Date(eighteenYearsAgo.setFullYear(eighteenYearsAgo.getFullYear() - 18));

        query.lessThanOrEqualTo('birthdate', eighteenYearsAgo);

    }
}
