export enum EmergencyEnum {
    new = 0,
    emergencyReceived = 1,
    firstresponderReady = 2,
    firstrespondersOnTheWay = 3,
    firstrespondersArrived = 4,
    abortedByAllFirstresponders = 10,
    successfullyFinished = 11,
    aborted = 12,
    done = 20,
    doneAndPostProcessed = 21
}

export enum EmergencyTaskEnum {
    initial = 0,
    firstaid = 1,
    getaed = 2,
    voluntary = 3
}

export let EmergencyTaskEnumText = new Map<EmergencyTaskEnum, string>()
    .set(EmergencyTaskEnum.initial, 'Das System entscheidet')
    .set(EmergencyTaskEnum.firstaid, 'Ersthelfer')
    .set(EmergencyTaskEnum.getaed, 'AED Holer')
    .set(EmergencyTaskEnum.voluntary, 'Spontanhelfer');

export enum EmergencyTypeEnum {
    firstresponder = 1,
    spontaneousHelper = 2
}

export let EmergencyTypeEnumText = new Map<EmergencyTypeEnum, string>()
    .set(EmergencyTypeEnum.firstresponder, 'Ersthelfer')
    .set(EmergencyTypeEnum.spontaneousHelper, 'Spontanhelfer');

export enum EmergencyStateEnum {
    initial = 0,
    received = 1,
    ready = 2,
    accepted = 3,
    arrived = 4,
    aborted = 5,
    finished = 6,
    calledBack = 7,
    contacted = 8,
    outOfRegion = 9,
    tooOld = 10,
    expired = 11,
    exception = 12
}

export let EmergencyStateEnumText = new Map<EmergencyStateEnum, string>()
    .set(EmergencyStateEnum.initial, '0 - versendet')
    .set(EmergencyStateEnum.received, '1 - empfangen')
    .set(EmergencyStateEnum.ready, '2 - angesehen')
    .set(EmergencyStateEnum.accepted, '3 - akzeptiert')
    .set(EmergencyStateEnum.arrived, '4 - angekommen')
    .set(EmergencyStateEnum.aborted, '5 - abgebrochen')
    .set(EmergencyStateEnum.finished, '6 - beendet')
    .set(EmergencyStateEnum.calledBack, '7 - zurückgerufen')
    .set(EmergencyStateEnum.contacted, '8 - kontaktiert')
    .set(EmergencyStateEnum.outOfRegion, '9 - zu weit weg')
    .set(EmergencyStateEnum.tooOld, '10 - zu alt')
    .set(EmergencyStateEnum.expired, '11 - abgelaufen')
    .set(EmergencyStateEnum.exception, '12 - fehler');

    export enum EmergencyStateRecordedTimeEnum {
        initial = 0,
        received = 1,
        ready = 2,
        accepted = 3,
        arrived = 4,
        aborted = 5,
        finished = 6,
        calledBack = 7,
        expired = 11,
        send = 12
    }
    
    export let EmergencyStateRecordedTimeEnumText = new Map<EmergencyStateRecordedTimeEnum, string>()
        .set(EmergencyStateRecordedTimeEnum.initial, 'Erstellt')
        .set(EmergencyStateRecordedTimeEnum.expired, 'Abgelaufen')
        .set(EmergencyStateRecordedTimeEnum.received, 'Empfangen')
        .set(EmergencyStateRecordedTimeEnum.ready, 'Angesehen')
        .set(EmergencyStateRecordedTimeEnum.accepted, 'Akzeptiert')
        .set(EmergencyStateRecordedTimeEnum.arrived, 'Angekommen')
        .set(EmergencyStateRecordedTimeEnum.aborted, 'Abgebrochen')
        .set(EmergencyStateRecordedTimeEnum.finished, 'Beendet')
        .set(EmergencyStateRecordedTimeEnum.calledBack, 'Zurückgerufen')
        .set(EmergencyStateRecordedTimeEnum.send, 'Versendet');
