import { Injectable } from '@angular/core';
import { ErrorService, ParseService, Parse } from '../services/index';
import { Area } from '../models/index';

@Injectable()
export class AreaService {

    constructor(private errorService: ErrorService, private parseService: ParseService) {
    }

    public get(): Promise<Array<Area>> {
        return new Promise<Array<Area>>((resolve, reject) => {
            const query = new Parse.Query(Area);
            query.find().then(areaList => resolve(areaList), error => this.errorService.handleParseErrors(error));
        });
    }
}
