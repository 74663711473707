
import { BaseModel, Parse } from './base/index';
import { Area } from './area';

export class Event extends BaseModel {
    public static PARSE_CLASSNAME = 'Event';

    private _zip: string;
    private _street: string;
    private _areaRelation: Parse.Relation<Area>;
    private _from: Date;
    private _city: string;
    private _information: string;
    private _phone: string;
    private _date: Date;
    private _organizer: string;
    private _activated: boolean;
    private _eventUrl: string;
    private _title: string;
    private _imageObject: Parse.File;
    private _to: Date;
    private _email: string;
    private _object: string;

    constructor() {
        super(Event.PARSE_CLASSNAME);
    }


    public get zip(): string {
        return this._zip;
    }

    public set zip(value: string) {
        this._zip = value;
    }

    public get street(): string {
        return this._street;
    }

    public set street(value: string) {
        this._street = value;
    }

    public get areaRelation(): Parse.Relation<Area> {
        return this._areaRelation;
    }

    public set areaRelation(value: Parse.Relation<Area>) {
        this._areaRelation = value;
    }

    public get from(): Date {
        return this._from;
    }

    public set from(value: Date) {
        this._from = value;
    }

    public get city(): string {
        return this._city;
    }

    public set city(value: string) {
        this._city = value;
    }

    public get information(): string {
        return this._information;
    }

    public set information(value: string) {
        this._information = value;
    }

    public get phone(): string {
        return this._phone;
    }

    public set phone(value: string) {
        this._phone = value;
    }

    public get date(): Date {
        return this._date;
    }

    public set date(value: Date) {
        this._date = value;
    }

    public get organizer(): string {
        return this._organizer;
    }

    public set organizer(value: string) {
        this._organizer = value;
    }

    public get activated(): boolean {
        return this._activated;
    }

    public set activated(value: boolean) {
        this._activated = value;
    }

    public get eventUrl(): string {
        return this._eventUrl;
    }

    public set eventUrl(value: string) {
        this._eventUrl = value;
    }

    public get title(): string {
        return this._title;
    }

    public set title(value: string) {
        this._title = value;
    }

    public get imageObject(): Parse.File {
        return this._imageObject;
    }

    public set imageObject(value: Parse.File) {
        this._imageObject = value;
    }

    public get to(): Date {
        return this._to;
    }

    public set to(value: Date) {
        this._to = value;
    }

    public get email(): string {
        return this._email;
    }

    public set email(value: string) {
        this._email = value;
    }

    public get object(): string {
        return this._object;
    }

    public set object(value: string) {
        this._object = value;
    }
}

BaseModel.registerClass(Event, Event.PARSE_CLASSNAME);
