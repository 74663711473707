import { Component, ElementRef, Input, OnInit, Renderer2 } from '@angular/core';
import { Router } from '@angular/router';

// Import navigation elements
import { navigation } from './../../_nav';
import { EmergencyEnum } from 'app/data/common/models/transient/emergency.enums';
import { EmergencyService } from 'app/data/common/modelservices/emergency.service';
import { UserService } from 'app/data/common/modelservices/user.service';
import AuthenticationService from 'app/data/app/services/authentication.service';
import { DefibrillatorService, EmergencyStateService } from 'app/data/common/modelservices';


@Component({
    selector: 'app-sidebar-nav',
    template: `
    <nav class="sidebar-nav">
      <ul class="nav">
        <ng-template ngFor let-navitem [ngForOf]="navigation">
          <li *ngIf="isDivider(navitem)" class="nav-divider"></li>
          <ng-template [ngIf]="isTitle(navitem)&&isPrivileged(navitem)">
            <app-sidebar-nav-title [title]='navitem'></app-sidebar-nav-title>
          </ng-template>
          <ng-template [ngIf]="!isDivider(navitem)&&!isTitle(navitem)&&isPrivileged(navitem)">
            <app-sidebar-nav-item [item]='navitem'></app-sidebar-nav-item>
          </ng-template>
        </ng-template>
      </ul>
    </nav>`
})
export class AppSidebarNavComponent {

    public navigation = navigation;

    public isDivider(item) {
        return item.divider ? true : false
    }

    public isTitle(item) {
        return item.title ? true : false
    }

    public isPrivileged(item) {
        if (!item.requiresPrivileges || !(item.requiresPrivileges instanceof Array)) {
            return true;
        }
        for (const requiredPrivilege of item.requiresPrivileges) {
            if (!this.authenticationService.hasPrivilege(requiredPrivilege)) {
                return false;
            }
        }
        return true;
    }

    constructor(private authenticationService: AuthenticationService) { }
}

@Component({
    selector: 'app-sidebar-nav-item',
    template: `
    <ng-template [ngIf]="isPrivileged(item)">
        <li *ngIf="!isDropdown(); else dropdown" [ngClass]="hasClass() ? 'nav-item ' + item.class : 'nav-item'">
        <app-sidebar-nav-link [link]='item'></app-sidebar-nav-link>
        </li>
        <ng-template #dropdown>
        <li [ngClass]="hasClass() ? 'nav-item nav-dropdown ' + item.class : 'nav-item nav-dropdown'"
            [class.open]="isActive()"
            routerLinkActive="open"
            appNavDropdown>
            <app-sidebar-nav-dropdown [link]='item'></app-sidebar-nav-dropdown>
        </li>
        </ng-template>
      </ng-template>
    `
})
export class AppSidebarNavItemComponent {
    @Input() item: any;

    public hasClass() {
        return this.item.class ? true : false
    }

    public isDropdown() {
        return this.item.children ? true : false
    }

    public thisUrl() {
        return this.item.url
    }

    public isActive() {
        return this.router.isActive(this.thisUrl(), false)
    }

    public isPrivileged(item) {
        if (!item.requiresPrivileges || !(item.requiresPrivileges instanceof Array)) {
            return true;
        }
        for (const requiredPrivilege of item.requiresPrivileges) {
            if (!this.authenticationService.hasPrivilege(requiredPrivilege)) {
                return false;
            }
        }
        return true;
    }

    constructor(private router: Router, private authenticationService: AuthenticationService) {}

}

@Component({
    selector: 'app-sidebar-nav-link',
    template: `
    <a *ngIf="!isExternalLink(); else external"
      [ngClass]="hasVariant() ? 'nav-link nav-link-' + link.variant : 'nav-link'"
      routerLinkActive="active"
      [routerLink]="[link.url]">
      <i *ngIf="isIcon()" class="{{ link.icon }}"></i>
      {{ link.name }}
      <span *ngIf="isBadge()" [ngClass]="'badge badge-' + link.badge.variant">{{ link.badge.text }}</span>
      <span *ngIf="isSpecialBadge()" [ngClass]="'special-badge badge-pill badge-' + link.badge.variant">{{ link.badge.text }}</span>
      <span *ngIf="isSecondSpecialBadge()" [ngClass]="'special-badge badge-pill badge-' + link.badgeSecond.variant">{{ link.badgeSecond.text }}</span>
    </a>
    <ng-template #external>
      <a [ngClass]="hasVariant() ? 'nav-link nav-link-' + link.variant : 'nav-link'" href="{{link.url}}">
        <i *ngIf="isIcon()" class="{{ link.icon }}"></i>
        {{ link.name }}
        <span *ngIf="isBadge()" [ngClass]="'badge badge-' + link.badge.variant">{{ link.badge.text }}</span>
      </a>
    </ng-template>
  `
})
export class AppSidebarNavLinkComponent {
    private _link: any

    @Input() public set link(value: any) {
        this._link = value;
        if (this.isSpecialBadge()) {
            this.link.badge.text = '';
            switch (this.link.badge.special) {
                case 'active-emergencies':
                    this.emergencyService.subActiveEmergencies().then(sub => {
                        this.link.badge.text = sub.state.size.toString();
                        sub.onNext(state => {
                            this.link.badge.text = state.state.size.toString();
                        })
                    })
                    break;
                case 'open-protocols':
                    this.emergencyService.countByState(EmergencyEnum.done, true).then(count => {
                        this.link.badge.text = count.toString();
                    });
                    // this.emergencyService.subByState(EmergencyEnum.done).then(sub => {
                    //     this.link.badge.text = sub.state.size.toString();
                    //     sub.onNext(state => {
                    //         this.link.badge.text = state.state.size.toString();
                    //     })
                    // })
                    break;
                case 'users':
                    this.userService.count().then(count => {
                        if (count.toString().length > 4) {
                            this.link.badge.text = count.toString().substring(0,2) + 'k'
                        } else {
                            this.link.badge.text = count.toString();
                        }
                        
                    });
                    break;
                case 'emergencies-total':
                    this.emergencyStateService.count().then(count => {
                        if (count.toString().length > 4) {
                            this.link.badge.text = count.toString().substring(0,2) + 'k'
                        } else {
                            this.link.badge.text = count.toString();
                        }
                    });
                    break;
                case 'all-users':
                    this.userService.count().then(count => {
                        if (count.toString().length > 4) {
                            this.link.badge.text = count.toString().substring(0,2) + 'k'
                        } else {
                            this.link.badge.text = count.toString();
                        }
                    });
                    break;
            };

            if (this.isSecondSpecialBadge()) {
                this.link.badgeSecond.text = '';
                switch (this.link.badgeSecond.special) {
                    case 'unchecked-users':
                        this.userService.countUnchecked().then(count => {
                            this.link.badgeSecond.text = count.toString();
                        });
                        break;
                }
            }
        }
    }

    public get link() {
        return this._link;
    }

    public hasVariant() {
        return this.link.variant ? true : false
    }

    public isBadge() {
        return this.link.badge && !this.link.badge.special ? true : false
    }

    public isSpecialBadge() {
        return this.link.badge && this.link.badge.special ? true : false
    }

    public isSecondSpecialBadge() {
        return this.link.badgeSecond && this.link.badgeSecond.special ? true : false
    }

    public isExternalLink() {
        return this.link.url.substring(0, 4) === 'http' ? true : false
    }

    public isIcon() {
        return this.link.icon ? true : false
    }

    constructor(private emergencyStateService: EmergencyStateService, private emergencyService: EmergencyService, private userService: UserService) {
    }
}

@Component({
    selector: 'app-sidebar-nav-dropdown',
    template: `
    <a class="nav-link nav-dropdown-toggle" appNavDropdownToggle href="#">
      <i *ngIf="isIcon()" class="{{ link.icon }}"></i>
      {{ link.name }}
      <span *ngIf="isBadge()" [ngClass]="'badge badge-' + link.badge.variant">{{ link.badge.text }}</span>
      <span *ngIf="isSpecialBadge()" [ngClass]="'special-badge badge-pill badge-' + link.badge.variant">{{ link.badge.text }}</span>
    </a>
    <ul class="nav-dropdown-items">
      <ng-template ngFor let-child [ngForOf]="link.children">
        <app-sidebar-nav-item [item]='child'></app-sidebar-nav-item>
      </ng-template>
    </ul>
  `
})
export class AppSidebarNavDropdownComponent {
    private _link: any
    public _userCount: number

    @Input() public set link(value: any) {
        this._link = value;
        if (this.isSpecialBadge()) {
            this.link.badge.text = '';
            switch (this.link.badge.special) {
                case 'defibrillators':
                    this.defibrillatorService.count().then(count => {
                        if (count.toString().length > 4) {
                            this.link.badge.text = count.toString().substring(0,2) + 'k'
                        } else {
                            this.link.badge.text = count.toString();
                        }
                    });
                    break;
                
            }
        }
    }

    public get link() {
        return this._link;
    }

    public hasVariant() {
        return this.link.variant ? true : false
    }

    public isBadge() {
        return this.link.badge && !this.link.badge.special ? true : false
    }

    public isSpecialBadge() {
        return this.link.badge && this.link.badge.special ? true : false;
    }

    public isIcon() {
        return this.link.icon ? true : false
    }

    constructor(private defibrillatorService: DefibrillatorService) {
     }
}

@Component({
    selector: 'app-sidebar-nav-title',
    template: ''
})
export class AppSidebarNavTitleComponent implements OnInit {
    @Input() title: any;

    constructor(private el: ElementRef, private renderer: Renderer2) { }

    ngOnInit() {
        const nativeElement: HTMLElement = this.el.nativeElement;
        const li = this.renderer.createElement('li');
        const name = this.renderer.createText(this.title.name);

        this.renderer.addClass(li, 'nav-title');

        if (this.title.class) {
            const classes = this.title.class;
            this.renderer.addClass(li, classes);
        }

        if (this.title.wrapper && this.title.wrapper.element) {
            const wrapper = this.renderer.createElement(this.title.wrapper.element);

            this.renderer.appendChild(wrapper, name);
            this.renderer.appendChild(li, wrapper);
        } else {
            this.renderer.appendChild(li, name);
        }
        this.renderer.appendChild(nativeElement, li)
    }
}

export const APP_SIDEBAR_NAV = [
    AppSidebarNavComponent,
    AppSidebarNavDropdownComponent,
    AppSidebarNavItemComponent,
    AppSidebarNavLinkComponent,
    AppSidebarNavTitleComponent
];
