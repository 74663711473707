import { BaseModel, Parse } from './base/index';
import { User } from './user';

export enum OperationStateEnum {
    unkown = 0,
    badenwurttemberg = 1,
    bayern = 2,
    berlin = 3,
    brandenburg = 4,
    bremen = 5,
    hamburg = 6,
    hessen = 7,
    mecklenburgvorpommern = 8,
    niedersachsen = 9,
    nordrheinwestfalen = 10,
    rheinlandpfalz = 11,
    saarland = 12,
    sachsen = 13,
    sachsenanhalt = 14,
    schleswigholstein = 15,
    thuringen = 16
}

export enum OperationDurationEnum {
    unknown = 0,
    onetime = 1,
    recurring = 2,
}

export enum SpontaneousOperationStateEnum {
    activated = 1,
    completed = 2,
    draft = 3,
    aborted = 4
}

export enum TypeOperationEnum {
    elderlyCare = 1,
    ambulanceService = 2,
    heavyTask = 3,
    generalHelp = 4
}

export let OperationStateEnumText = new Map<OperationStateEnum, string>()
    .set(OperationStateEnum.badenwurttemberg, 'Baden-Württemberg')
    .set(OperationStateEnum.bayern, 'Bayern')
    .set(OperationStateEnum.berlin, 'Berlin')
    .set(OperationStateEnum.brandenburg, 'Brandenburg')
    .set(OperationStateEnum.bremen, 'Bremen')
    .set(OperationStateEnum.hamburg, 'Hamburg')
    .set(OperationStateEnum.hessen, 'Hessen')
    .set(OperationStateEnum.mecklenburgvorpommern, 'Mecklenburg-Vorpommern')
    .set(OperationStateEnum.niedersachsen, 'Niedersachsen')
    .set(OperationStateEnum.nordrheinwestfalen, 'Nordrhein-Westfalen')
    .set(OperationStateEnum.rheinlandpfalz, 'Rheinlandpfalz')
    .set(OperationStateEnum.saarland, 'Saarland')
    .set(OperationStateEnum.sachsen, 'Sachsen')
    .set(OperationStateEnum.sachsenanhalt, 'Sachsen-Anhalt')
    .set(OperationStateEnum.schleswigholstein, 'Schleswig-Holstein')
    .set(OperationStateEnum.thuringen, 'Thüringen');


export let OperationDurationEnumText = new Map<OperationDurationEnum, string>()
    .set(OperationDurationEnum.onetime, 'Einmalig')
    .set(OperationDurationEnum.recurring, 'Wiederkehrend')
    .set(OperationDurationEnum.unknown, 'Noch unbekannt');


export let TypeOperationEnumText = new Map<TypeOperationEnum, string>()
    .set(TypeOperationEnum.elderlyCare, 'Seniorenbetreuung')
    .set(TypeOperationEnum.ambulanceService, 'Sanitätsdienst')
    .set(TypeOperationEnum.heavyTask, 'Schwere Aufgaben')
    .set(TypeOperationEnum.generalHelp, 'Allgemeine Sontanhilfe');


export let SpontaneousOperationStateEnumText = new Map<SpontaneousOperationStateEnum, string>()
    .set(SpontaneousOperationStateEnum.activated, 'Aktiv')
    .set(SpontaneousOperationStateEnum.completed, 'Beendet')
    .set(SpontaneousOperationStateEnum.draft, 'Entwurf')
    .set(SpontaneousOperationStateEnum.aborted, 'Abgebrochen');

export class SpontaneousOperation extends BaseModel {
    public static PARSE_CLASSNAME = 'SpontaneousOperation';

    private _id: string;
    private _operationDate: Date;
    private _briefingStartDate: Date;
    private _briefingEndDate: Date;
    private _operationAddressState: OperationStateEnum;
    private _operationAddressDescription: string;
    private _operationAddressDistrict: string;
    private _operationAddressStreet: string;
    private _operationAddressZip: string;
    private _operationAddressCity: string;
    private _briefingAddressStreet: string;
    private _briefingAddressNr: string;
    private _briefingAddressZip: string;
    private _briefingAddressCity: string;
    private _briefingAddressLocation: Parse.GeoPoint;
    private _operationDescription: string;
    private _typeOfOperation: Array<TypeOperationEnum>;
    private _operationDuration: OperationDurationEnum;
    private _operationManager: string;
    private _phoneNumber: string;
    private _email: string;
    private _state: SpontaneousOperationStateEnum;
    private _accepted: Parse.Relation<User>;
    private _declined: Parse.Relation<User>;
    private _spontaneousHelpersCount: number;

    constructor() {
        super(SpontaneousOperation.PARSE_CLASSNAME);
    }

    public get id(): string {
        return this._id;
    }

    public set id(value: string) {
        this._id = value;
    }

    public get operationDate(): Date {
        return this._operationDate;
    }
    public set operationDate(value: Date) {
        this._operationDate = value;
    }

    public get briefingStartDate(): Date {
        return this._briefingStartDate;
    }
    public set briefingStartDate(value: Date) {
        this._briefingStartDate = value;
    }

    public get briefingEndDate(): Date {
        return this._briefingEndDate;
    }
    public set briefingEndDate(value: Date) {
        this._briefingEndDate = value;
    }

    public get operationAddressState(): OperationStateEnum {
        return this._operationAddressState;
    }
    public set operationAddressState(value: OperationStateEnum) {
        this._operationAddressState = value;
    }

    public get operationAddressDistrict(): string {
        return this._operationAddressDistrict;
    }
    public set operationAddressDistrict(value: string) {
        this._operationAddressDistrict = value;
    }

    public get operationAddressDescription(): string {
        return this._operationAddressDescription;
    }
    public set operationAddressDescription(value: string) {
        this._operationAddressDescription = value;
    }

    public get operationAddressStreet(): string {
        return this._operationAddressStreet;
    }
    public set operationAddressStreet(value: string) {
        this._operationAddressStreet = value;
    }

    public get operationAddressZip(): string {
        return this._operationAddressZip;
    }
    public set operationAddressZip(value: string) {
        this._operationAddressZip = value;
    }

    public get operationAddressCity(): string {
        return this._operationAddressCity;
    }
    public set operationAddressCity(value: string) {
        this._operationAddressCity = value;
    }

    public get briefingAddressStreet(): string {
        return this._briefingAddressStreet;
    }
    public set briefingAddressStreet(value: string) {
        this._briefingAddressStreet = value;
    }

    public get briefingAddressNr(): string {
        return this._briefingAddressNr;
    }
    public set briefingAddressNr(value: string) {
        this._briefingAddressNr = value;
    }

    public get briefingAddressZip(): string {
        return this._briefingAddressZip;
    }
    public set briefingAddressZip(value: string) {
        this._briefingAddressZip = value;
    }

    public get briefingAddressCity(): string {
        return this._briefingAddressCity;
    }
    public set briefingAddressCity(value: string) {
        this._briefingAddressCity = value;
    }

    public get operationDescription(): string {
        return this._operationDescription;
    }
    public set operationDescription(value: string) {
        this._operationDescription = value;
    }

    public get typeOfOperation(): Array<TypeOperationEnum> {
        return this._typeOfOperation;
    }
    public set typeOfOperation(value: Array<TypeOperationEnum>) {
        this._typeOfOperation = value;
    }

    public get operationDuration(): OperationDurationEnum {
        return this._operationDuration;
    }
    public set operationDuration(value: OperationDurationEnum) {
        this._operationDuration = value;
    }

    public get operationManager(): string {
        return this._operationManager;
    }
    public set operationManager(value: string) {
        this._operationManager = value;
    }

    public get phoneNumber(): string {
        return this._phoneNumber;
    }
    public set phoneNumber(value: string) {
        this._phoneNumber = value;
    }

    public get email(): string {
        return this._email;
    }
    public set email(value: string) {
        this._email = value;
    }

    public get accepted(): Parse.Relation<User> {
        return this._accepted;
    }

    public set accepted(value: Parse.Relation<User>) {
        this._accepted = value;
    }

    public get declined(): Parse.Relation<User> {
        return this._declined;
    }

    public set declined(value: Parse.Relation<User>) {
        this._declined = value;
    }

    public get state(): SpontaneousOperationStateEnum {
        return this._state;
    }

    public set state(value: SpontaneousOperationStateEnum) {
        this._state = value;
    }

    public get spontaneousHelpersCount(): number {
        return this._spontaneousHelpersCount;
    }

    public set spontaneousHelpersCount(value: number) {
        this._spontaneousHelpersCount = value;
    }

    public get briefingAddressLocation(): Parse.GeoPoint {
        return this._briefingAddressLocation;
    }

    public set briefingAddressLocation(value: Parse.GeoPoint) {
        this._briefingAddressLocation = value;
    }

    public get briefingAddress(): string {
        return '' +
        (this.briefingAddressStreet != undefined ? this.briefingAddressStreet : '') +
        (this.briefingAddressNr != undefined ? ' ' + this.briefingAddressNr : '') +
        (this.briefingAddressZip != undefined ? ', ' + this.briefingAddressZip : '') +
        (this.briefingAddressCity != undefined ? ' ' + this.briefingAddressCity : '');
    }

    public get operationAddress(): string {
        return '' +
        (this.operationAddressState != undefined ? OperationStateEnumText.get(Number(this.operationAddressState)) : '') +
        (this.operationAddressDistrict != undefined ? '; ' + this.operationAddressDistrict : '') +
        (this.operationAddressZip != undefined ? '; ' + this.operationAddressZip : '') +
        (this.operationAddressCity != undefined ? ' ' + this.operationAddressCity : '');
    }

}

BaseModel.registerClass(SpontaneousOperation, SpontaneousOperation.PARSE_CLASSNAME);
