import { BaseModel, Parse } from './base/index';
import { ControlCenter } from './control-center';
import { User } from './user';

export class CCFirstresponder extends BaseModel {
    public static PARSE_CLASSNAME = 'CCFirstresponder';

    private _username: string;
    private _activated: boolean;
    private _controlCenterRelation: ControlCenter;
    private _userRelation: User;

    constructor() {
        super(CCFirstresponder.PARSE_CLASSNAME);
    }


    get activated(): boolean {
        return this._activated;
    }

    set activated(value: boolean) {
        this._activated = value;
    }

    get userRelation(): User {
        return this._userRelation;
    }

    set userRelation(value: User) {
        this._userRelation = value;
    }

    get username(): string {
        return this._username;
    }

    set username(value: string) {
        this._username = value;
    }

    get controlCenterRelation(): ControlCenter {
        return this._controlCenterRelation;
    }

    set controlCenterRelation(value: ControlCenter) {
        this._controlCenterRelation = value;
    }
}

BaseModel.registerClass(CCFirstresponder, CCFirstresponder.PARSE_CLASSNAME);
