import { Component, Input } from '@angular/core';
import { ViewDirective } from 'app/views/view';
import { Injector } from '@angular/core';
import { IDefibrillatorCluster, IClusteredDefibrillators, DefibrillatorService } from 'app/data/modelservices';
import { ViewChild } from '@angular/core';
import { GoogleMap } from '@angular/google-maps/index.js'
// import { LatLngBounds } from '@agm/core/services/google-maps-types';
import { Defibrillator, DefibrillatorState } from 'app/data/common/models/defibrillator';
import MarkerService from 'app/data/app/services/marker.service';

@Component({
  templateUrl: 'app-defi-map.component.html',
  selector: 'app-defi-map'
})
export class AppDefiMapComponent {

  public Array = Array;

  @Input()
  set latLngBounds(latLngBounds: google.maps.LatLngBounds) {
    this._latLngBounds = latLngBounds;
    this.onBoundsChange(latLngBounds);
  }

  @Input()
  public zoom: number;
  public DefibrillatorState = DefibrillatorState;

  public markerSet: IClusteredDefibrillators = null;
  private changeCount = 0;
  private clusterIdentMap: Map<string, IDefibrillatorCluster>;
  private defiIdentMap: Map<string, Defibrillator>;
  private _latLngBounds: google.maps.LatLngBounds;

  constructor(private defibrillatorService: DefibrillatorService, public marker: MarkerService) {
  }

  public onBoundsChange(latLngBounds: google.maps.LatLngBounds) {
    if (latLngBounds) {
      const _current = this;
      const currentChangeCount = ++this.changeCount;
      setTimeout(() => {
        if (currentChangeCount === _current.changeCount) {
          this.defibrillatorService.getClusteredForArea([latLngBounds.getSouthWest().lat(), latLngBounds.getSouthWest().lng(), latLngBounds.getNorthEast().lat(), latLngBounds.getNorthEast().lng()], this.zoom).then(markerSet => {
            if (!this.markerSet) {
              this.markerSet = markerSet;
            } else {
              // Add all new ones
              for (const clusterID of Array.from(markerSet.clusters.keys())) {
                if (this.markerSet.clusters.has(clusterID)) {
                  this.markerSet.clusters.get(clusterID).size = markerSet.clusters.get(clusterID).size;
                } else {
                  this.markerSet.clusters.set(clusterID, markerSet.clusters.get(clusterID));
                }
              }

              for (const defiID of Array.from(markerSet.defibrillators.keys())) {
                if (!this.markerSet.defibrillators.has(defiID)) {
                  this.markerSet.defibrillators.set(defiID, markerSet.defibrillators.get(defiID));
                }
              }

              // Remove old ones
              for (const clusterID of Array.from(this.markerSet.clusters.keys())) {
                if (!markerSet.clusters.has(clusterID)) {
                  this.markerSet.clusters.delete(clusterID)
                }
              }

              for (const defiID of Array.from(this.markerSet.defibrillators.keys())) {
                if (!markerSet.defibrillators.has(defiID)) {
                  this.markerSet.defibrillators.delete(defiID);
                }
              }
            }
          });
        }
      }, 200)
    }
  }

  public getMarkerByDefiState(state: DefibrillatorState) {
    switch (state) {
      case DefibrillatorState.inApproval:
        return this.marker.icon_aed_in_approval;
      case DefibrillatorState.available:
        return this.marker.icon_aed_available;
      case DefibrillatorState.verified:
        return this.marker.icon_aed_verified;
      case DefibrillatorState.childSupport:
          return this.marker.icon_aed_childSupport;
    }
  }
}
