import { BaseModel, Parse } from './base/index';
import { AreaPoint } from './area-point';

export class Area extends BaseModel {
    public static PARSE_CLASSNAME = 'Area';

    private _description: string;
    private _areaPointRelation: Parse.Relation<AreaPoint>;

    constructor() {
        super(Area.PARSE_CLASSNAME);
    }

    public get description(): string {
        return this._description;
    }

    public set description(value: string) {
        this._description = value;
    }

    public get areaPointRelation(): Parse.Relation<AreaPoint> {
        return this._areaPointRelation;
    }

    public set areaPointRelation(value: Parse.Relation<AreaPoint>) {
        this._areaPointRelation = value;
    }
}

BaseModel.registerClass(Area, Area.PARSE_CLASSNAME);
