import { Injectable } from '@angular/core';
import { UserContractBasic } from '../models/index';
import { ErrorService, ParseService, Parse } from '../services/index';
import { BaseModelService } from './base/base-modelservice';

@Injectable()
export class UserContractBasicService extends BaseModelService<UserContractBasic> {

    constructor(protected errorService: ErrorService, protected parseService: ParseService) {
        super(errorService, parseService, UserContractBasic);
    }

    public getById(id: string): Promise<UserContractBasic> {
        return new Promise<UserContractBasic>((resolve, reject) => {
            const query = this.createQuery();
            query.equalTo('objectId', id);
            query.first().then(certificate => resolve(certificate), error => this.errorService.handleParseErrors(error));
        });
    }
}
