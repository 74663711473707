import { BaseModel, Parse } from './base/index';
import { ControlCenter } from './control-center';
import { UserProfessionEnum, UserQualificationEnum } from './transient/index';

export interface ConfigurationConstraints {
    professions?: Array<UserProfessionEnum>;
    qualifications?: Array<UserQualificationEnum>;
}

export class Configuration extends BaseModel {
    public static PARSE_CLASSNAME = 'Configuration';

    private _distance: number;
    private _controlCenterRelation: ControlCenter;
    private _isRescuerCountLimited: boolean;
    private _rescuerLimitation: number;
    private _constraints: ConfigurationConstraints;
    private _isAEDFirstresponder: boolean;
    private _isGlobalFirstresponder: boolean;
    private _isStreetRouting: boolean;

    constructor() {
        super(Configuration.PARSE_CLASSNAME);
    }

    public get distance(): number {
        return this._distance;
    }

    public set distance(value: number) {
        this._distance = value;
    }

    public get controlCenterRelation(): ControlCenter {
        return this._controlCenterRelation;
    }

    public set controlCenterRelation(value: ControlCenter) {
        this._controlCenterRelation = value;
    }

    public get constraints(): ConfigurationConstraints {
        return this._constraints;
    }

    public set constraints(value: ConfigurationConstraints) {
        this._constraints = value;
    }

    public get isAEDFirstresponder(): boolean {
        return this._isAEDFirstresponder;
    }

    public set isAEDFirstresponder(value: boolean) {
        this._isAEDFirstresponder = value;
    }

    public get isGlobalFirstresponder(): boolean {
        return this._isGlobalFirstresponder;
    }

    public set isGlobalFirstresponder(value: boolean) {
        this._isGlobalFirstresponder = value;
    }

    public get isRescuerCountLimited(): boolean {
        return this._isRescuerCountLimited;
    }

    public set isRescuerCountLimited(value: boolean) {
        this._isRescuerCountLimited = value;
    }

    public get rescuerLimitation(): number {
        return this._rescuerLimitation;
    }

    public set rescuerLimitation(value: number) {
        this._rescuerLimitation = value;
    }

    public get isStreetRouting(): boolean {
        return this._isStreetRouting;
    }

    public set isStreetRouting(value: boolean) {
        this._isStreetRouting = value;
    }
}

BaseModel.registerClass(Configuration, Configuration.PARSE_CLASSNAME);
