import { Injectable } from '@angular/core';
import { ErrorService } from '../services/error.service';
import { ParseService, Parse } from '../services/parse.service';
import { AlertingRegion, ControlCenter } from '../models/index';
import { BaseModelService } from './base/base-modelservice';

@Injectable()
export class AlertingRegionService extends BaseModelService<AlertingRegion> {

    constructor(protected errorService: ErrorService, protected parseService: ParseService) {
      super(errorService, parseService, AlertingRegion);
    }

    public getByControlCenter(controlCenter: ControlCenter, activatedOnly: boolean = false): Promise<Array<AlertingRegion>> {
        return new Promise<Array<AlertingRegion>>((resolve, reject) => {
            const query = this.createQuery();
            query.equalTo('controlCenterRelation', controlCenter);
            if (activatedOnly) {
                query.equalTo('activated', true);
            }
            query.find().then(regions => resolve(regions), error => this.errorService.handleParseErrors(error));
        });
    }

   public getByIdActivated(id: String, activatedOnly: boolean = false): Promise<AlertingRegion> {
        return new Promise<AlertingRegion>((resolve, reject) => {
            const query = this.createQuery();
            query.equalTo('objectId', id);
            if (activatedOnly) {
                query.equalTo('activated', true);
            }
            query.first().then(region => resolve(region), error => this.errorService.handleParseErrors(error));
        });
    }
}
