import { BaseModel, IPointer } from './base/index';

export enum CertificateStateEnum {
    initial = 1,
    approved = 2
}

export enum CertificateTypeEnum {
    mainCertificate = 1,
    additionalCertificate = 2
}

export class Certificate extends BaseModel {
    public static PARSE_CLASSNAME = 'Certificate';

    private _state: CertificateStateEnum;
    private _title: string;
    private _type: CertificateTypeEnum;
    private _file: Parse.File;
    private _stateText: string;

    constructor() {
        super(Certificate.PARSE_CLASSNAME);
    }

    public get state(): CertificateStateEnum {
        return this._state;
    }

    public set state(value: CertificateStateEnum) {
        this._state = value;
    }

    public get title(): string {
        return this._title;
    }

    public set title(value: string) {
        this._title = value;
    }

    public get type(): CertificateTypeEnum {
        return this._type;
    }

    public set type(value: CertificateTypeEnum) {
        this._type = value;
    }

    public get file(): Parse.File {
        return this._file;
    }

    public set file(value: Parse.File) {
        this._file = value;
    }

    public get stateText(): string {
        return this._stateText;
    }

    public set stateText(value: string) {
        this._stateText = value;
    }
}

BaseModel.registerClass(Certificate, Certificate.PARSE_CLASSNAME);
