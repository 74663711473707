import { BaseModel, Parse } from './base/index';
import { Area } from './area';

export class News extends BaseModel {
    public static PARSE_CLASSNAME = 'News';

    private _abstract: string;
    private _areaRelation: Parse.Relation<Area>;
    private _newsType: string;
    private _activated: boolean;
    private _subtitle: string;
    private _title: string;
    private _imageUrl: string;
    private _imageObject: Parse.File;
    private _newsUrl: string;

    constructor() {
        super(News.PARSE_CLASSNAME);
    }

    public get abstract(): string {
        return this._abstract;
    }

    public set abstract(value: string) {
        this._abstract = value;
    }

    public get newsType(): string {
        return this._newsType;
    }

    public set newsType(value: string) {
        this._newsType = value;
    }

    public get activated(): boolean {
        return this._activated;
    }

    public set activated(value: boolean) {
        this._activated = value;
    }

    public get subtitle(): string {
        return this._subtitle;
    }

    public set subtitle(value: string) {
        this._subtitle = value;
    }

    public get title(): string {
        return this._title;
    }

    public set title(value: string) {
        this._title = value;
    }

    public get imageUrl(): string {
        return this._imageUrl;
    }

    public set imageUrl(value: string) {
        this._imageUrl = value;
    }

    public get imageObject(): Parse.File {
        return this._imageObject;
    }

    public set imageObject(value: Parse.File) {
        this._imageObject = value;
    }

    public get newsUrl(): string {
        return this._newsUrl;
    }

    public set newsUrl(value: string) {
        this._newsUrl = value;
    }
}

BaseModel.registerClass(News, News.PARSE_CLASSNAME);
