
export enum UserApprovalStateEnum {
    newRecord = 0,
	validationSuccess = 1,
	documentMissing = 2,
    documentInvalid = 3,
    signatureMissing = 4,
    signatureInvalid = 5,
    informationMissing = 6,
    informationInvalid = 7,
    underage = 8,
    markedAsChecked = 99
}

export let UserApprovalStateEnumText = new Map<UserApprovalStateEnum, string>()
    .set(UserApprovalStateEnum.newRecord, 'Neue Registrierung')
    .set(UserApprovalStateEnum.validationSuccess, 'Prüfung ausstehend')
    .set(UserApprovalStateEnum.documentMissing, 'Nachweis fehlt')
    .set(UserApprovalStateEnum.documentInvalid, 'Nachweis ungültig')
    .set(UserApprovalStateEnum.signatureMissing, 'Unterschrift fehlt')
    .set(UserApprovalStateEnum.signatureInvalid, 'Unterschrift ungültig')
    .set(UserApprovalStateEnum.informationMissing, 'Informationen fehlen')
    .set(UserApprovalStateEnum.informationInvalid, 'Informationen ungültig')
    .set(UserApprovalStateEnum.underage, 'Minderjährig')
    .set(UserApprovalStateEnum.markedAsChecked, 'Manuell geprüft')


export enum UserQualificationEnum {
    BLSTraining = 1,
    EHCourse = 2,
    paramedic = 3,
    criticalCareParamedic = 4,
    doctor = 5,
    doctorWithLabelEmergencyMedicine = 6,
    trainingAsParamedic = 7,
    ambulanceMan = 8
}

export let UserQualificationEnumText = new Map<UserQualificationEnum, string>()
    .set(UserQualificationEnum.BLSTraining, 'BLS-Schulung')
    .set(UserQualificationEnum.EHCourse, 'EH-Kurs')
    .set(UserQualificationEnum.paramedic, 'Rettungssanitäter')
    .set(UserQualificationEnum.criticalCareParamedic, 'Rettungsassistent/Notfallsanitäter')
    .set(UserQualificationEnum.doctor, 'Arzt')
    .set(UserQualificationEnum.doctorWithLabelEmergencyMedicine, 'Arzt mit Zusatzbezeichnung Notfallmedizin')
    .set(UserQualificationEnum.trainingAsParamedic, 'Sanitätsausbildung')
    .set(UserQualificationEnum.ambulanceMan, 'Rettungshelfer')

export enum UserProfessionEnum {
    doctor = 1,
    dentist = 2,
    doctorsAssistant = 3,
    paramedic = 4,
    paramedicAssistent = 5,
    policeman = 6,
    fireDepartment = 7,
    volunteerFireDepartment = 8,
    nursing = 9,
    aidAgency = 10,
    therapist = 11,
    germanArmedForce = 12,
    medicalStudent = 13,
    ambulanceMan = 14,
    other = 15
}

export let UserProfessionEnumText = new Map<UserProfessionEnum, string>()
    .set(UserProfessionEnum.doctor, 'Arzt')
    .set(UserProfessionEnum.dentist, 'Zahnarzt')
    .set(UserProfessionEnum.doctorsAssistant, 'Arzthelfer')
    .set(UserProfessionEnum.paramedic, 'Rettungssanitäter')
    .set(UserProfessionEnum.paramedicAssistent, 'Rettungsassistent')
    .set(UserProfessionEnum.policeman, 'Polizist')
    .set(UserProfessionEnum.fireDepartment, 'Feuerwehr (Beruflich)')
    .set(UserProfessionEnum.volunteerFireDepartment, 'Feuerwehr (Freiwillig)')
    .set(UserProfessionEnum.nursing, 'Krankenpflege')
    .set(UserProfessionEnum.aidAgency, 'Hilfsorganisation')
    .set(UserProfessionEnum.therapist, 'Therapeut')
    .set(UserProfessionEnum.germanArmedForce, 'Bundeswehr')
    .set(UserProfessionEnum.medicalStudent, 'Medizinstudent')
    .set(UserProfessionEnum.ambulanceMan, 'Notfallsanitäter')
    .set(UserProfessionEnum.other, 'Sonstige')
