import { BaseModel, Parse } from './base/index';
import { ContractBasic } from './contract-basic';

export class UserContractBasic extends BaseModel {
    public static PARSE_CLASSNAME = 'UserContractBasic';

    private _signedAt: Date;
    private _signature: Parse.File;
    private _contract: ContractBasic;

    constructor() {
        super(UserContractBasic.PARSE_CLASSNAME);
    }

    public get signedAt(): Date {
        return this._signedAt;
    }

    public set signedAt(value: Date) {
        this._signedAt = value;
    }

    public get signature(): Parse.File {
        return this._signature;
    }

    public set signature(value: Parse.File) {
        this._signature = value;
    }

    public get contract(): ContractBasic {
        return this._contract;
    }

    public set contract(value: ContractBasic) {
        this._contract = value;
    }
}

BaseModel.registerClass(UserContractBasic, UserContractBasic.PARSE_CLASSNAME);

