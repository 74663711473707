// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `angular-cli.json`.

export const environment = {
  PRODUCTION: '1',
  MAPS_API_KEY: 'AIzaSyCOXCfObOn7IvekHA_Q6YEhIVEuND3dkDM',
  NODE_ENV: 'production',
  PARSE: {
    URL: 'https://server.savinglife.de/parse',
    APP_ID: 'sfFzOxotpsdO4567HWJE3nMmDfaFR0erLgFoe73335cttKvVGC9CzIM',
    JS_KEY: 'sfndsBFS434z78FSBFu36bscjshbv',
    IS_PARSE_SERVER: '0'
  }
};
