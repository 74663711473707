import { BaseModel, Parse } from './base/index';
import { User } from './user';
import { ParseService } from '../services/index';

export enum InstallationDeviceEnum {
    ios = 'ios',
    android = 'android',
}

export class Installation extends Parse.Installation {
    public static PARSE_CLASSNAME = '_Installation';

    private _parseVersion: string;
    private _userRelation: User;
    private _appIdentifier: string;
    private _appName: string;
    private _appVersion: string;
    private _localeIdentifier: string;
    private _deviceType: InstallationDeviceEnum;
    private _location: Parse.GeoPoint;

    constructor() {
        super();
    }

    public get parseVersion(): string {
        return this._parseVersion;
    }

    public set parseVersion(value: string) {
        this._parseVersion = value;
    }

    public get userRelation(): User {
        return this._userRelation;
    }

    public set userRelation(value: User) {
        this._userRelation = value;
    }

    public get appIdentifier(): string {
        return this._appIdentifier;
    }

    public set appIdentifier(value: string) {
        this._appIdentifier = value;
    }

    public get appName(): string {
        return this._appName;
    }

    public set appName(value: string) {
        this._appName = value;
    }

    public get appVersion(): string {
        return this._appVersion;
    }

    public set appVersion(value: string) {
        this._appVersion = value;
    }

    public get deviceType(): InstallationDeviceEnum {
        return this._deviceType;
    }

    public set deviceType(value: InstallationDeviceEnum) {
        this._deviceType = value;
    }

    public get location(): Parse.GeoPoint {
        return this._location;
    }

    public set location(value: Parse.GeoPoint) {
        this._location = value;
    }

    public save(): Promise<this> {
        for (const attrKey of Object.keys(this)) {
            if (attrKey[0] === '_' && attrKey !== '_objCount') {
                this.set(attrKey.substr(1), this[attrKey]);
            }
        }
        if (ParseService.isParseServer()) {
            return super.save(null, { useMasterKey: true });
        } else {
            return super.save();
        }
    }

    public get localeIdentifier() {
        return this._localeIdentifier;
    }

    public set localeIdentifier(value) {
        this._localeIdentifier = value;
    }

    private _setExisted(isExisted: boolean) {
        if (!this.existed()) {
            for (const attrKey of Object.keys(this)) {
                if (attrKey[0] === '_' && attrKey !== '_objCount' && attrKey !== '_id') {
                    delete this[attrKey];
                }
            }
        }

        for (const attrKey of Object.keys(this.attributes)) {
            if (this[attrKey] === undefined) {
                this['_' + attrKey] = this.get(attrKey);
            }
        }
        super['_setExisted'](isExisted);
    }
}

BaseModel.registerClass(Installation, Installation.PARSE_CLASSNAME);
