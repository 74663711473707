
import { ToastrModule, ToastrService } from 'ngx-toastr';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { LocationStrategy, HashLocationStrategy } from '@angular/common';
import { CookieService } from 'ngx-cookie-service';

import { AppComponent } from './app.component';

// Import containers
import {
    FullLayoutComponent,
    SimpleLayoutComponent
} from './containers';

const APP_CONTAINERS = [
    FullLayoutComponent,
    SimpleLayoutComponent
]

// Import components
import {
    AppBreadcrumbsComponent,
    AppFooterComponent,
    AppHeaderComponent,
    AppSidebarComponent,
    AppSidebarFooterComponent,
    AppSidebarFormComponent,
    AppSidebarHeaderComponent,
    AppSidebarMinimizerComponent,
    APP_SIDEBAR_NAV
} from './components';

const APP_COMPONENTS = [
    AppBreadcrumbsComponent,
    AppFooterComponent,
    AppHeaderComponent,
    AppSidebarComponent,
    AppSidebarFooterComponent,
    AppSidebarFormComponent,
    AppSidebarHeaderComponent,
    AppSidebarMinimizerComponent,
    APP_SIDEBAR_NAV
]

// Import directives
import {
    AsideToggleDirective,
    NAV_DROPDOWN_DIRECTIVES,
    ReplaceDirective,
    SIDEBAR_TOGGLE_DIRECTIVES
} from './directives';

const APP_DIRECTIVES = [
    AsideToggleDirective,
    NAV_DROPDOWN_DIRECTIVES,
    ReplaceDirective,
    SIDEBAR_TOGGLE_DIRECTIVES
]

// Import routing module
import { AppRoutingModule } from './app.routing';

// Import 3rd party components
import { NgxEchartsModule } from 'ngx-echarts';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { ModalModule } from 'ngx-bootstrap/modal';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { NgSelectModule } from '@ng-select/ng-select';
import AuthenticationService from 'app/data/app/services/authentication.service';
import { APP_INITIALIZER } from '@angular/core';
import { Injector } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { GoogleMapsModule } from '@angular/google-maps';
import { environment } from 'environments/environment';
import * as $ from 'jquery';
import { UserService, EmergencyService, ControlCenterService, RoleService, EmergencyStateService, DefibrillatorService } from './data/common/modelservices/index';
import MarkerService from './data/app/services/marker.service';
import { ErrorService, ParseService } from './data/common/services';

@NgModule({
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        AppRoutingModule,
        NgxEchartsModule.forRoot({
            echarts: () => import('echarts')
        }),
        BsDropdownModule.forRoot(),
        ModalModule.forRoot(),
        TabsModule.forRoot(),
        ToastrModule.forRoot({
            extendedTimeOut: 1000,
            easing: 'ease-in',
            closeButton: true,
            enableHtml: true,
            progressBar: true,
            positionClass: 'toast-bottom-right',
            preventDuplicates: true
        }),
        NgSelectModule,
        FormsModule,
        GoogleMapsModule,
        // AgmCoreModule.forRoot({
        //     libraries: ['drawing'],
        //     apiKey: environment.MAPS_API_KEY
        // })
    ],
    declarations: [
        AppComponent,
        ...APP_CONTAINERS,
        ...APP_COMPONENTS,
        ...APP_DIRECTIVES
    ],
    providers: [
        AuthenticationService,
        CookieService,
        {
            provide: LocationStrategy,
            useClass: HashLocationStrategy
        },
        {
            provide: APP_INITIALIZER,
            useFactory: AppModule.onAppInit,
            multi: true,
            deps: [Injector]
        },
        ControlCenterService,
        UserService,
        ErrorService,
        ParseService,
        RoleService,
        EmergencyService,
        EmergencyStateService,
        DefibrillatorService,
        MarkerService,
        ToastrService
    ],
    bootstrap: [AppComponent]
})

export class AppModule {
    private static onAppInit(injector: Injector) {
        return () => {
            return new Promise<void>((resolve, reject) => {
                injector.get(AuthenticationService).initialize().then(() => resolve());
            })
        }
    }
}
