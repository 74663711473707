import { NgModule } from '@angular/core';
import { AppDefiMapComponent } from './app-defi-map.component';
import { GoogleMapsModule } from '@angular/google-maps';
import { CommonModule } from '@angular/common';

@NgModule({
    imports: [
        GoogleMapsModule,
        CommonModule
    ],
    exports: [AppDefiMapComponent],
    declarations: [AppDefiMapComponent]
})
export class AppDefiMapModule { }
