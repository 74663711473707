import { Injectable } from '@angular/core';
import { ErrorService, ParseService, Parse, Subscription } from '../services/index';
import { SpontaneousOperation, User, Installation } from '../models/index';
import { BaseModelService } from './base/base-modelservice';
import { SpontaneousOperationStateEnum, TypeOperationEnum } from '../models/spontaneousOperation';
import { PageInfo } from '../models/transient/page-info';
import moment from 'moment';
import axios, { AxiosResponse } from 'axios';

type FilterCallbackSH = (query: Parse.Query, filterString: string) => any;

@Injectable()
export class SpontaneousOperationService extends BaseModelService<SpontaneousOperation> {

    constructor(errorService: ErrorService, parseService: ParseService) {
        super(errorService, parseService, SpontaneousOperation);
    }

    public subUsersById(sOP: SpontaneousOperation): Promise<Subscription<User>> {
        return new Promise<Subscription<User>>((resolve, reject) => {
          const query = sOP.accepted;
          this.parseService.subscribe<User>(query.query()).then(subscription => resolve(subscription));
        });
    }

    public subById(id: string): Promise<Subscription<SpontaneousOperation>> {
        return new Promise<Subscription<SpontaneousOperation>>((resolve, reject) => {
          const query = this.createQuery();
          query.equalTo('objectId', id);
          this.parseService.subscribe<SpontaneousOperation>(query).then(subscription => resolve(subscription));
        });
    }

    public getByFilter(searchQuery?: string, pageInfo?: PageInfo): Promise<Array<SpontaneousOperation>> {
        return new Promise<Array<SpontaneousOperation>>((resolve, reject) => {
            let query = this.createQuery();
            if (pageInfo) {
                pageInfo.filterQuery = searchQuery;
                query = this.applyPageInfo(query, pageInfo, this.filterByAttributes);
            } else {
                query = this.applyFilter(query, searchQuery, this.filterByAttributes);
            }
            query.find().then(jobs => resolve(jobs), error => this.errorService.handleParseErrors(error));
        });
    }

    private filterByAttributes(query: Parse.Query<SpontaneousOperation>, filterQuery: string): Parse.Query<SpontaneousOperation> {
        const queries = new Array<Parse.Query<SpontaneousOperation>>();
        ['operationManager', 'description', 'briefingAddressStreet', 'operationAddressState', 'operationAddressDistrict', 'operationAddressCity', 'operationAddressStreet', 'briefingAddressCity'].forEach((attribute) => {
            const orQuery = new Parse.Query(SpontaneousOperation);
            orQuery.matches(attribute, new RegExp(filterQuery), 'i');
            queries.push(orQuery);
        });
        return Parse.Query.or<SpontaneousOperation>(...queries);
    }

    public countSpontaneousHelpers(id: string): Promise<number> {
        return new Promise<number>((resolve, reject) => {
            const query = this.createQuery();
            query.get(id).then(obj => {
                obj.accepted.query().count().then(cnt => resolve(cnt), error => this.errorService.handleParseErrors(error));
            });
        });
    }

    public getUsersBySOPId(sOP: SpontaneousOperation, searchQuery?: string, pageInfo?: PageInfo): Promise<Array<User>> {
        return new Promise<Array<User>>((resolve, reject) => {
            let query = sOP.accepted.query() as Parse.Query<User>;
            if (pageInfo) {
                pageInfo.filterQuery = searchQuery;
                query = this.applyPageInfoForSH(query, pageInfo, this.filterByAttributesSH);
            } else {
                query = this.applyFilterForSH(query, searchQuery, this.filterByAttributesSH);
            }
            query.find().then(users => resolve(users), error => this.errorService.handleParseErrors(error));
        });
    }

    private applyPageInfoForSH(query: Parse.Query<User>, pageInfo: PageInfo, filterCallback?: FilterCallbackSH): Parse.Query<User> {
        if (pageInfo.filterQuery && filterCallback) {
            query = this.applyFilterForSH(query, pageInfo.filterQuery, filterCallback);
        }

        if (pageInfo) {
            query.count().then((count) => pageInfo.totalCount = count);
            query.limit(pageInfo.rowLimit);
            query.skip(pageInfo.pageOffset * pageInfo.rowLimit);
            if (pageInfo.orderAsc) {
                query.ascending(pageInfo.order);
            } else {
                query.descending(pageInfo.order);
            }
        }
        return query;
    }

    private applyFilterForSH(query: Parse.Query<User>, filterQuery: string, filterCallback: FilterCallbackSH): Parse.Query<User> {
        return filterCallback(query, filterQuery);
    }

    private filterByAttributesSH(query: Parse.Query<User>, filterQuery: string): Parse.Query<User> {
        const queries = new Array<Parse.Query<User>>();
        ['firstname', 'lastname', 'email'].forEach((attribute) => {
            const orQuery = new Parse.Query(User);
            orQuery.matches(attribute, new RegExp(filterQuery), 'i');
            queries.push(orQuery);
        });
        return Parse.Query.or<User>(...queries);
    }

    public getAcceptedByUserId(id: String, userId: String): Promise<Array<SpontaneousOperation>> {
        return new Promise<Array<SpontaneousOperation>>((resolve, reject) => {
            const soQuery = this.createQuery();
            soQuery.equalTo('state', SpontaneousOperationStateEnum.activated);
            soQuery.equalTo('objectId', id);

            const userQuery = new Parse.Query(User);
            userQuery.equalTo('objectId', userId);

            soQuery.matchesQuery('accepted', userQuery);
            soQuery.find().then(operations => resolve(operations), error => this.errorService.handleParseErrors(error));
        });
    }

    public getDeclinedByUserId(id: String, userId: String): Promise<Array<SpontaneousOperation>> {
        return new Promise<Array<SpontaneousOperation>>((resolve, reject) => {
            const soQuery = this.createQuery();
            soQuery.equalTo('state', SpontaneousOperationStateEnum.activated);
            soQuery.equalTo('objectId', id);

            const userQuery = new Parse.Query(User);
            userQuery.equalTo('objectId', userId);

            soQuery.matchesQuery('declined', userQuery);
            soQuery.find().then(operations => resolve(operations), error => this.errorService.handleParseErrors(error));
        });
    }

    public getPendingAnswer(userId: String): Promise<Array<SpontaneousOperation>> {
        return new Promise<Array<SpontaneousOperation>>(async(resolve, reject) => {
            moment.locale('de');

            const soQuery = this.createQuery();
            soQuery.equalTo('state', SpontaneousOperationStateEnum.activated);
            // soQuery.greaterThanOrEqualTo('acceptUntil', { __type: 'Date', iso: moment().utcOffset(0, true).toISOString() });

            const acceptedQuery = new Parse.Query(User);
            acceptedQuery.equalTo('objectId', userId);

            const declinedQuery = new Parse.Query(User);
            declinedQuery.equalTo('objectId', userId);

            soQuery.doesNotMatchQuery('accepted', acceptedQuery);
            soQuery.doesNotMatchQuery('declined', declinedQuery);

            soQuery.find().then(operations => resolve(operations), error => this.errorService.handleParseErrors(error));
        });
    }

    public getOperationByState(state: SpontaneousOperationStateEnum): Promise<Array<SpontaneousOperation>> {
        return new Promise<Array<SpontaneousOperation>>((resolve, reject) => {
            const soQuery = this.createQuery();
            soQuery.equalTo('state', state);
            soQuery.addAscending('createdAt');

            soQuery.find().then(operations => resolve(operations), error => this.errorService.handleParseErrors(error));
        });
    }

    /* public modifyUserRelation(operationId: String, key: string, relationId: String, add: Boolean): Promise<Parse.Cloud.HttpResponse> {
        return new Promise<Parse.Cloud.HttpResponse>((resolve, reject) => {
            Parse.Cloud.httpRequest({
                method: 'PUT',
                url: process.env.SERVER_URL + process.env.PARSE_MOUNT + '/classes/' + SpontaneousOperation.PARSE_CLASSNAME + '/' + operationId,
                headers: {
                  'X-Parse-Application-Id': process.env.APP_ID,
                  'X-Parse-Master-Key': process.env.MASTER_KEY,
                  'Content-Type': 'application/json'
                },
                body: '{"' + key + '": {"__op": "' + (add ? 'AddRelation' : 'RemoveRelation') + '", "objects": [{"__type": "Pointer", "className": "_User", "objectId": "' + relationId + '"}]}}'
            }).then(response => resolve(response), error => this.errorService.handleParseErrors(error));
        });
    }*/

    public modifyUserRelation(operationId: String, key: string, relationId: String, add: Boolean): Promise<AxiosResponse> {
        return new Promise<AxiosResponse>(async (resolve, reject) => {
            try {
                const response = await axios({
                    method: 'PUT',
                    url: process.env.SERVER_URL + process.env.PARSE_MOUNT + '/classes/' + SpontaneousOperation.PARSE_CLASSNAME + '/' + operationId,
                    headers: {
                      'X-Parse-Application-Id': process.env.APP_ID,
                      'X-Parse-Master-Key': process.env.MASTER_KEY,
                      'Content-Type': 'application/json'
                    },
                    data: '{"' + key + '": {"__op": "' + (add ? 'AddRelation' : 'RemoveRelation') + '", "objects": [{"__type": "Pointer", "className": "_User", "objectId": "' + relationId + '"}]}}'
                });
                resolve(response);
            } catch (error) {
                console.log(error);
                reject(error);
            }
        });
    }

}
