import { BaseModel, Parse } from './base/index';

export class ContractBasic extends BaseModel {
    public static PARSE_CLASSNAME = 'ContractBasic';

    private _validUntil: Date;
    private _validFrom: Date;
    private _url: string;
    private _state: string;
    private _subtitle: string;
    private _version: string;
    private _title: string;

    constructor() {
        super(ContractBasic.PARSE_CLASSNAME);
    }

    public get validUntil(): Date {
        return this._validUntil;
    }

    public set validUntil(value: Date) {
        this._validUntil = value;
    }

    public get validFrom(): Date {
        return this._validFrom;
    }

    public set validFrom(value: Date) {
        this._validFrom = value;
    }

    public get url(): string {
        return this._url;
    }

    public set url(value: string) {
        this._url = value;
    }

    public get state(): string {
        return this._state;
    }

    public set state(value: string) {
        this._state = value;
    }

    public get subtitle(): string {
        return this._subtitle;
    }

    public set subtitle(value: string) {
        this._subtitle = value;
    }

    public get version(): string {
        return this._version;
    }

    public set version(value: string) {
        this._version = value;
    }

    public get title(): string {
        return this._title;
    }

    public set title(value: string) {
        this._title = value;
    }

}

BaseModel.registerClass(ContractBasic, ContractBasic.PARSE_CLASSNAME);

