import { Injectable } from '@angular/core';
import { ErrorService, ParseService, Parse } from '../services/index';
import { Event } from '../models/index';

@Injectable()
export class EventService {

    constructor(private errorService: ErrorService, private parseService: ParseService) {
    }

    public get(): Promise<Array<Event>> {
        return new Promise<Array<Event>>((resolve, reject) => {
            const query = new Parse.Query(Event);
            query.find().then(newsList => resolve(newsList), error => this.errorService.handleParseErrors(error));
        });
    }
}
