import { Injectable } from '@angular/core';
import { ErrorService, ParseService, Parse } from '../services/index';
import { ContractBasic } from '../models/index';
import { BaseModelService } from './base/base-modelservice';

@Injectable()
export class ContractBasicService extends BaseModelService<ContractBasic> {

    constructor(protected errorService: ErrorService, protected parseService: ParseService) {
        super(errorService, parseService, ContractBasic);
    }

    public getCurrent(): Promise<ContractBasic> {
        return new Promise<ContractBasic>((resolve, reject) => {
            const query = new Parse.Query(ContractBasic);
            query.equalTo('title', 'Basisvereinbarung');
            query.greaterThan('validUntil', new Date());
            query.lessThan('validFrom', new Date());
            query.first().then(contractBasic => resolve(contractBasic), error => this.errorService.handleParseErrors(error));
        });
    }
}
