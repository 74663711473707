import { BaseModel, Parse } from './base/index';
import { User } from './user';


export class Session extends Parse.Session {
    public static PARSE_CLASSNAME = '_Session';

    private _expiresAt: Date;
    private _user: User;
    private _installationId: String;
    private _restricted: boolean;

    constructor() {
        super();
    }

    public get user(): User {
        return this._user;
    }

    public set user(value: User) {
        this._user = value;
    }

    public get expiresAt(): Date {
        return this._expiresAt;
    }

    public set expiresAt(value: Date) {
        this._expiresAt = value;
    }
}

BaseModel.registerClass(Session, Session.PARSE_CLASSNAME);
