import { Injectable } from '@angular/core';
import { ErrorService, ParseService, Parse } from '../services/index';
import { User, Logging, LoggingTypeEnum } from '../models/index';

@Injectable()
export class LoggingService {

    constructor(private errorService: ErrorService, private parseService: ParseService) {
    }

    public getMailLogByUser(user: User): Promise<Array<Logging>> {
        return new Promise<Array<Logging>>((resolve, reject) => {
            const query = new Parse.Query(Logging);
            query.equalTo('relatedClass', User.PARSE_CLASSNAME);
            query.equalTo('relatedId', user.id);
            query.equalTo('type', LoggingTypeEnum.emailSent);
            query.descending('createdAt');
            query.find().then(loggingList => resolve(loggingList), error => this.errorService.handleParseErrors(error));
        });
    }

    public getCountMailLogByUser(user: User): Promise<number> {
        return new Promise<number>((resolve, reject) => {
            const query = new Parse.Query(Logging);
            query.equalTo('relatedClass', User.PARSE_CLASSNAME);
            query.equalTo('relatedId', user.id);
            query.equalTo('type', LoggingTypeEnum.emailSent);
            query.count().then(count => resolve(count), error => this.errorService.handleParseErrors(error));
        });
    }
}
