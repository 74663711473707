import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import AuthenticationService from 'app/data/app/services/authentication.service';

// Import Containers
import {
    FullLayoutComponent,
    SimpleLayoutComponent
} from './containers';
import { RolePrivilegeEnum } from 'app/data/common/models/transient/role.enums';

export const routes: Routes = [
    {
        path: '',
        canActivateChild: [AuthenticationService],
        children: [
            {
                path: '',
                redirectTo: 'app/dashboard/control-center',
                pathMatch: 'full',
            },
            {
                path: 'app',
                component: FullLayoutComponent,
                data: {
                    title: 'Home',
                    requiresAuthentication: true
                },
                children: [
                    {
                        path: 'defibrillator',
                        data: {
                            requiresPrivileges: [RolePrivilegeEnum.route_defibrillator]
                        },
                        loadChildren: () => import('./views/defibrillator/defibrillator.module').then(m => m.DefibrillatorModule)
                    },
                    {
                        path: 'region',
                        data: {
                            requiresPrivileges: [RolePrivilegeEnum.route_region]
                        },
                        loadChildren: () => import('./views/region/region.module').then(m => m.RegionModule)
                    },
                    {
                        path: 'emergency',
                        loadChildren: () => import('./views/emergency/emergency.module').then(m => m.EmergencyModule)
                    },
                    {
                        path: 'user',
                        data: {
                            requiresPrivileges: [RolePrivilegeEnum.route_user]
                        },
                        loadChildren: () => import('./views/user/user.module').then(m => m.UserModule)
                    },
                    {
                        path: 'jobManagement',
                        data: {
                            requiresPrivileges: [RolePrivilegeEnum.route_user]
                        },
                        loadChildren: () => import('./views/jobManagement/jobManager.module').then(m => m.JobManagerModule)
                    },
                    {
                        path: 'spontaneousHelper',
                        data: {
                            requiresPrivileges: [RolePrivilegeEnum.route_spontaneousHelper]
                        },
                        loadChildren: () => import('./views/spontaneousHelper/spontaneousHelper.module').then(m => m.SpontaneousHelperModule)
                    },
                    {
                        path: 'social',
                        data: {
                            requiresPrivileges: [RolePrivilegeEnum.route_social]
                        },
                        loadChildren: () => import('./views/social/social.module').then(m => m.SocialModule)
                    },
                    {
                        path: 'course',
                        data: {
                            requiresPrivileges: [RolePrivilegeEnum.route_social]
                        },
                        loadChildren: () => import('./views/course/course.module').then(m => m.CourseModule)
                    },
                    {
                        path: 'dashboard',
                        loadChildren: () => import('./views/dashboard/dashboard.module').then(m => m.DashboardModule)
                    },
                    {
                        path: 'ccfirstresponder',
                        data: {
                            requiresPrivileges: [RolePrivilegeEnum.route_ccfirstresponder]
                        },
                        loadChildren: () => import('./views/ccfirstresponder/ccfirstresponder.module').then(m => m.CCFirstresponderModule)
                    },
                    {
                        path: 'changelog',
                        loadChildren: () => import('./views/changeLog/changeLog.module').then(m => m.ChangeLogModule)
                    },
                    {
                        path: 'help',
                        loadChildren: () => import('./views/help/help.module').then(m => m.HelpModule)
                    },
                    {
                        path: 'components',
                        loadChildren: () => import('./views/components/components.module').then(m => m.ComponentsModule)
                    },
                    {
                        path: 'icons',
                        loadChildren: () => import('./views/icons/icons.module').then(m => m.IconsModule)
                    },
                    {
                        path: 'plugins',
                        loadChildren: () => import('./views/plugins/plugins.module').then(m => m.PluginsModule)
                    },
                    {
                        path: 'widgets',
                        loadChildren: () => import('./views/widgets/widgets.module').then(m => m.WidgetsModule)
                    }
                ]
            },
            {
                path: 'print',
                data: {
                    title: 'Print',
                    requiresAuthentication: true
                },
                children: [
                    {
                        path: 'emergency',
                        data: {
                            requiresPrivileges: [RolePrivilegeEnum.route_protocolList]
                        },
                        loadChildren: () => import('./views/emergency/emergency.module').then(m => m.EmergencyModule)
                    }
                ]
            },
            {
                path: 'pages',
                component: SimpleLayoutComponent,
                data: {
                    title: 'Pages',
                    requiresAuthentication: false,
                },
                children: [
                    {
                        path: '',
                        loadChildren: () => import('./views/pages/pages.module').then(m => m.PagesModule),
                    }
                ]
            }]
    }
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class AppRoutingModule { }
