import { Injectable } from '@angular/core';
import { BaseModelService } from './base/base-modelservice';
import { ErrorService, ParseService, Parse, Subscription } from '../services/index';
import { Emergency, EmergencyEnum, EmergencyState, EmergencyStateEnum, PageInfo, User } from '../models/index';

@Injectable()
export class EmergencyService extends BaseModelService<Emergency> {

  constructor(errorService: ErrorService, parseService: ParseService) {
    super(errorService, parseService, Emergency);
  }

  public getByState(state: EmergencyEnum, pageInfo?: PageInfo): Promise<Array<Emergency>> {
    return new Promise<Array<Emergency>>((resolve, reject) => {
      let query = this.createQuery();
      query.greaterThanOrEqualTo('state', state);
      if (pageInfo) { query = this.applyPageInfo(query, pageInfo, this.filterByFirstresponders); }
      query.find().then(emergencies => resolve(emergencies), error => this.errorService.handleParseErrors(error));
    });
  }

  private filterByFirstresponders(query: Parse.Query<Emergency>, filterQuery: string): Parse.Query<Emergency> {

    const emergencyQueries = new Array<Parse.Query<Emergency>>();
    ['emergencyNumberDC', 'informationString', 'keyword'].forEach((attribute) => {
      for (const filterSubQuery of (filterQuery.replace(',', ' ').replace('  ', ' ').split(' '))) {
        const orEmergencyQuery = new Parse.Query(Emergency);
        orEmergencyQuery.exists('controlCenterRelation');
        orEmergencyQuery.equalTo('controlCenterRelation', Parse.User.current().get('controlCenterRelation'));
        orEmergencyQuery.greaterThanOrEqualTo('state', EmergencyEnum.done);
        orEmergencyQuery.limit(999999);
        orEmergencyQuery.matches(attribute, new RegExp(filterSubQuery), 'i');
        emergencyQueries.push(orEmergencyQuery);
      }
    });

    const userQueries = new Array<Parse.Query<User>>();
    ['firstname', 'lastname'].forEach((attribute) => {
      for (const filterSubQuery of (filterQuery.replace(',', ' ').replace('  ', ' ').split(' '))) {
        const orQuery = new Parse.Query(User);
        orQuery.matches(attribute, new RegExp(filterSubQuery), 'i');
        userQueries.push(orQuery);
      }
    });
    const userQuery = Parse.Query.or<User>(...userQueries);
    const emergencyStateQuery = new Parse.Query(EmergencyState);
    const emergencyQuery = new Parse.Query(Emergency);
    emergencyStateQuery.containedIn('state', [EmergencyStateEnum.finished, EmergencyStateEnum.aborted]);
    emergencyStateQuery.matchesKeyInQuery('userRelation', 'objectId', userQuery);
    emergencyQuery.matchesKeyInQuery('objectId', 'emergencyRelation.objectId', emergencyStateQuery);
    emergencyQueries.push(emergencyQuery);

    return Parse.Query.or<Emergency>(...emergencyQueries);
  }

  public subById(emergencyId: string): Promise<Subscription<Emergency>> {
    return new Promise<Subscription<Emergency>>((resolve, reject) => {
      const query = this.createQuery();
      query.equalTo('objectId', emergencyId);
      this.parseService.subscribe<Emergency>(query).then(subscription => resolve(subscription));
    });
  }

  public countByState(state: EmergencyEnum, greaterThan: Boolean): Promise<Number> {
    return new Promise<Number>((resolve, reject) => {
      const query = this.createQuery();
      greaterThan ? query.greaterThanOrEqualTo('state', state) : query.lessThanOrEqualTo('state', state);
      query.count().then(value => resolve(value));
    });
  }

  public subByState(state: EmergencyEnum): Promise<Subscription<Emergency>> {
    return new Promise<Subscription<Emergency>>((resolve, reject) => {
      const query = this.createQuery();
      query.descending('createdAt');
      query.greaterThanOrEqualTo('state', state);
      this.parseService.subscribe<Emergency>(query).then(subscription => resolve(subscription));
    });
  }

  public subActiveEmergency(emergencyId: string): Promise<Subscription<Emergency>> {
    return new Promise<Subscription<Emergency>>((resolve, reject) => {
      const query = this.createQuery();
      query.equalTo('objectId', emergencyId);
      query.lessThan('state', 20);
      this.parseService.subscribe<Emergency>(query).then(subscription => resolve(subscription));
    });
  }

  public subActiveEmergencies(): Promise<Subscription<Emergency>> {
    return new Promise<Subscription<Emergency>>((resolve, reject) => {
      const query = this.createQuery();
      query.descending('createdAt');
      query.lessThan('state', EmergencyEnum.done);
      this.parseService.subscribe<Emergency>(query).then(subscription => {
        resolve(subscription)
      });
    });
  }

  public createQuery(includes?: [keyof Emergency]): Parse.Query<Emergency> {
    const query = super.createQuery(includes);
    if (!ParseService.isParseServer()) {
      query.exists('controlCenterRelation');
      query.equalTo('controlCenterRelation', Parse.User.current().get('controlCenterRelation'));
      query.limit(999999);
    }
    return query;
  }
}
