import { BaseModel, Parse } from './base/index';

export class Jobs extends BaseModel {
    public static PARSE_CLASSNAME = 'Jobs';

    private _name: string;
    private _description: string;
    private _minute: number;
    private _hour: number;
    private _day: number;
    private _month: number;
    private _dayOfWeek: number;
    private _activated: boolean;
    private _paramKey: string;
    private _paramValue: string;

    constructor() {
        super(Jobs.PARSE_CLASSNAME);
    }

    public get name(): string {
        return this._name;
    }

    public set name(value: string) {
        this._name = value;
    }

    public get description(): string {
        return this._description;
    }

    public set description(value: string) {
        this._description = value;
    }

    public get paramKey(): string {
        return this._paramKey;
    }

    public set paramKey(value: string) {
        this._paramKey = value;
    }

    public get paramValue(): string {
        return this._paramValue;
    }

    public set paramValue(value: string) {
        this._paramValue = value;
    }

    public get minute(): number {
        return this._minute;
    }

    public set minute(value: number) {
        this._minute = value;
    }

    public get hour(): number {
        return this._hour;
    }

    public set hour(value: number) {
        this._hour = value;
    }

    public get day(): number {
        return this._day;
    }

    public set day(value: number) {
        this._day = value;
    }

    public get month(): number {
        return this._month;
    }

    public set month(value: number) {
        this._month = value;
    }

    public get dayOfWeek(): number {
        return this._dayOfWeek;
    }

    public set dayOfWeek(value: number) {
        this._dayOfWeek = value;
    }

    public get activated(): boolean {
        return this._activated;
    }

    public set activated(value: boolean) {
        this._activated = value;
    }

    public get scheduledDate(): string {
        return '0 ' +
            (this.minute != undefined ? this.minute.toString() + ' ' : '* ') +
            (this.hour != undefined ? this.hour.toString() + ' ' : '* ') +
            (this.day != undefined ? this.day.toString() + ' ' : '* ') +
            (this.month != undefined ? this.month.toString() + ' ' : '* ') +
            (this.dayOfWeek != undefined ? this.dayOfWeek.toString() + ' ' : '*');
    }
}

BaseModel.registerClass(Jobs, Jobs.PARSE_CLASSNAME);
