import { Injectable } from '@angular/core';
import { ErrorService, ParseService, Parse } from '../services/index';
import { Role, RoleRestrictionEnum, User } from '../models/index';
import { resolve } from 'url';

@Injectable()
export class RoleService {

    constructor(private errorService: ErrorService, private parseService: ParseService) {
    }

    public userHasAnyRoles(roles: Array<String>, user: User): Promise<Boolean> {
        return new Promise<Boolean>((resolve, reject) => {
            const query = new Parse.Query(Parse.Role);
            query.containedIn('name', roles);
            query.equalTo('users', user);
            query.first().then(role => {
                if (!role) {
                    resolve(false);
                } else {
                    resolve(true);
                }
            }, error => this.errorService.handleParseErrors(error));
        });
    }

    public userHasRole(role: String, user: User): Promise<Boolean> {
        return new Promise<Boolean>((resolve, reject) => {
            const query = new Parse.Query(Parse.Role);
            query.equalTo('name', role);
            query.equalTo('users', user);
            query.first().then(role => {
                if (!role) {
                    resolve(false);
                } else {
                    resolve(true);
                }
            }, error => this.errorService.handleParseErrors(error));
        });
    }

    public getUserRoles(user: User): Promise<Array<Role>> {
        return new Promise<Array<Role>>((resolve, reject) => {
            const query = new Parse.Query(Role);
            query.equalTo('users', user);
            query.find().then(roles => {
                resolve(roles); // role hierarchy doesn't work like this.
                // this.addChildRoles(roles).then(allRoles => resolve(allRoles));
            }, error => this.errorService.handleParseErrors(error));
        });
    }


    public getUsersByRole(role: String): Promise<Array<User>> {
        return new Promise<Array<User>>((resolve, reject) => {
            const query = new Parse.Query(Role);
            query.equalTo('name', role);
            query.first().then(role => {
                role.get('users').query().find({ useMasterKey: true }).then(users => {
                    resolve(users);
                });
            }, error => this.errorService.handleParseErrors(error));
        });
    }

    private addChildRoles(roleArray: Array<Role>): Promise<Array<Role>> {
        return new Promise<Array<Role>>((resolve, reject) => {
            let resultRoles = roleArray;
            if (roleArray.length === 0) {
                resolve(roleArray);
            } else {
                roleArray.forEach((role) => {
                    role.roles.query().find().then((roles) => {
                        resultRoles = resultRoles.concat(roles as Array<Role>);
                        this.addChildRoles(roles as Array<Role>).then((additionalRoles) => {
                            resolve(resultRoles.concat(additionalRoles));
                        });
                    });
                });
            }
        });
    }
}
