import { Injectable } from '@angular/core';
import { ErrorService, ParseService, Parse, Subscription } from '../services/index';
import { EmergencyState, LocationTracking } from '../models/index';
import { BaseModelService } from './base/base-modelservice';

@Injectable()
export class LocationTrackingService extends BaseModelService<LocationTracking> {

    constructor(protected errorService: ErrorService, protected parseService: ParseService) {
        super(errorService, parseService, LocationTracking);
    }

    public subByEmergencyState(emergencyState: EmergencyState): Promise<Subscription<LocationTracking>> {
        return new Promise<Subscription<LocationTracking>>((resolve, reject) => {
            const query = new Parse.Query(LocationTracking);
            query.equalTo('emergencyStateRelation', emergencyState);
            query.descending('createdAt');
            this.parseService.subscribe<LocationTracking>(query).then(subscription => resolve(subscription));
        });
    }
}
