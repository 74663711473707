import { BaseModel, Parse } from './base/index';

export class AreaPoint extends BaseModel {
    public static PARSE_CLASSNAME = 'AreaPoint';

    private _location: Parse.GeoPoint;
    private _range: number;
    private _description: string;

    constructor() {
        super(AreaPoint.PARSE_CLASSNAME);
    }

    public get location(): Parse.GeoPoint {
        return this._location;
    }

    public set location(value: Parse.GeoPoint) {
        this._location = value;
    }

    public get range(): number {
        return this._range;
    }

    public set range(value: number) {
        this._range = value;
    }

    public get description(): string {
        return this._description;
    }

    public set description(value: string) {
        this._description = value;
    }
}

BaseModel.registerClass(AreaPoint, AreaPoint.PARSE_CLASSNAME);
