import { Injectable } from '@angular/core';

@Injectable()
export default class MarkerService {
  private marker_url: String = window.location.protocol + '//' + window.location.host + '/assets/img/markers/'
  private height: Number = 52;
  private width: Number = 40;
  private scaledSize: Object = {
    height: this.height,
    width: this.width
  };
  public icon_alarm = {
    url: this.marker_url + 'alarm.png',
    scaledSize: this.scaledSize
  };
  public icon_alarm_hover = {
    url: this.marker_url + 'alarm_hover.png',
    scaledSize: this.scaledSize
  };
  public icon_alarm_grey = {
    url: this.marker_url + 'alarm_grey.png',
    scaledSize: this.scaledSize
  };
  public icon_firstresponder = {
    url: this.marker_url + 'firstresponder.png',
    scaledSize: this.scaledSize
  };
  public icon_firstresponder_hover = {
    url: this.marker_url + 'firstresponder_hover.png',
    scaledSize: this.scaledSize
  };
  public icon_aed_in_approval = {
    url: this.marker_url + 'defibrillator_in_approval.png',
    scaledSize: this.scaledSize
  };
  public icon_aed_available = {
    url: this.marker_url + 'defibrillator_available.png',
    scaledSize: this.scaledSize
  };
  public icon_aed_verified = {
    url: this.marker_url + 'defibrillator_asb_verified.png',
    scaledSize: this.scaledSize
  };
  public icon_firstresponder_defi = {
    url: this.marker_url + 'firstresponder_defi.png',
    scaledSize: this.scaledSize
  };
  public icon_firstresponder_defi_hover = {
    url: this.marker_url + 'firstresponder_defi_hover.png',
    scaledSize: this.scaledSize
  };
  public icon_aed_childSupport = {
    url: this.marker_url + 'defibrillator_child_support.png',
    scaledSize: this.scaledSize
  }
  public icon_marker_helper = {
    url: this.marker_url + 'marker_helper.png',
    scaledSize: this.scaledSize
  }
  public icon_marker_briefing = {
    url: this.marker_url + 'marker_briefing.png',
    scaledSize: this.scaledSize
  }
  private cluster = {
    url: this.marker_url + 'cluster.png',
    scaledSize: {
      height: 40,
      width: 40
    }
  };
}
