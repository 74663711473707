import { BaseModel, Parse } from './base/base-model';

export enum CourseTypeEnum {
    placeholder_entry_a = 0,
    placeholder_entry_b = 1
}

export class Course extends BaseModel {
    public static PARSE_CLASSNAME = 'Course';

    private _zip: string;
    private _street: string;
    private _street_nr: string;
    private _from: Date;
    private _to: Date;
    private _city: string;
    private _name: string;
    private _phone: string;
    private _date: Date;
    private _organizer: string;
    private _units: number;
    private _activated: boolean;
    private _country: string;
    private _type: CourseTypeEnum;
    private _email: string;
    private _description: string;

    constructor() {
        super(Course.PARSE_CLASSNAME);
    }

    /**
     * Getter zip
     * @return {string}
     */
    public get zip(): string {
        return this._zip;
    }

    /**
     * Getter street
     * @return {string}
     */
    public get street(): string {
        return this._street;
    }

    /**
     * Getter street_nr
     * @return {string}
     */
    public get street_nr(): string {
        return this._street_nr;
    }

    /**
     * Getter from
     * @return {Date}
     */
    public get from(): Date {
        return this._from;
    }

    /**
     * Getter to
     * @return {Date}
     */
    public get to(): Date {
        return this._to;
    }

    /**
     * Getter city
     * @return {string}
     */
    public get city(): string {
        return this._city;
    }

    /**
     * Getter name
     * @return {string}
     */
    public get name(): string {
        return this._name;
    }

    /**
     * Getter phone
     * @return {string}
     */
    public get phone(): string {
        return this._phone;
    }

    /**
     * Getter date
     * @return {Date}
     */
    public get date(): Date {
        return this._date;
    }

    /**
     * Getter organizer
     * @return {string}
     */
    public get organizer(): string {
        return this._organizer;
    }

    /**
     * Getter units
     * @return {number}
     */
    public get units(): number {
        return this._units;
    }

    /**
     * Getter activated
     * @return {boolean}
     */
    public get activated(): boolean {
        return this._activated;
    }

    /**
     * Getter country
     * @return {string}
     */
    public get country(): string {
        return this._country;
    }

    /**
     * Getter type
     * @return {CourseTypeEnum}
     */
    public get type(): CourseTypeEnum {
        return this._type;
    }

    /**
     * Getter email
     * @return {string}
     */
    public get email(): string {
        return this._email;
    }

    /**
     * Getter description
     * @return {string}
     */
    public get description(): string {
        return this._description;
    }

    /**
     * Setter zip
     * @param {string} value
     */
    public set zip(value: string) {
        this._zip = value;
    }

    /**
     * Setter street
     * @param {string} value
     */
    public set street(value: string) {
        this._street = value;
    }

    /**
     * Setter street_nr
     * @param {string} value
     */
    public set street_nr(value: string) {
        this._street_nr = value;
    }

    /**
     * Setter from
     * @param {Date} value
     */
    public set from(value: Date) {
        this._from = value;
    }

    /**
     * Setter to
     * @param {Date} value
     */
    public set to(value: Date) {
        this._to = value;
    }

    /**
     * Setter city
     * @param {string} value
     */
    public set city(value: string) {
        this._city = value;
    }

    /**
     * Setter name
     * @param {string} value
     */
    public set name(value: string) {
        this._name = value;
    }

    /**
     * Setter phone
     * @param {string} value
     */
    public set phone(value: string) {
        this._phone = value;
    }

    /**
     * Setter date
     * @param {Date} value
     */
    public set date(value: Date) {
        this._date = value;
    }

    /**
     * Setter organizer
     * @param {string} value
     */
    public set organizer(value: string) {
        this._organizer = value;
    }

    /**
     * Setter units
     * @param {number} value
     */
    public set units(value: number) {
        this._units = value;
    }

    /**
     * Setter activated
     * @param {boolean} value
     */
    public set activated(value: boolean) {
        this._activated = value;
    }

    /**
     * Setter country
     * @param {string} value
     */
    public set country(value: string) {
        this._country = value;
    }

    /**
     * Setter type
     * @param {CourseTypeEnum} value
     */
    public set type(value: CourseTypeEnum) {
        this._type = value;
    }

    /**
     * Setter email
     * @param {string} value
     */
    public set email(value: string) {
        this._email = value;
    }

    /**
     * Setter description
     * @param {string} value
     */
    public set description(value: string) {
        this._description = value;
    }

    public get typeText(): string {
        return this.getTypeText(this.type);
    }

    public getTypeText(type: CourseTypeEnum): string {
        switch (type) {
            case CourseTypeEnum.placeholder_entry_a:
                return 'PLACEHOLDER A';
            case CourseTypeEnum.placeholder_entry_b:
                return 'PLACEHOLDER B';
            default:
                return '!! UNDEFINED_TYPE';
        }
    }
}

BaseModel.registerClass(Course, Course.PARSE_CLASSNAME);
