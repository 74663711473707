import { Injectable } from '@angular/core';
import { News } from '../models/index';
import { ErrorService, ParseService, Parse } from '../services/index';
import { BaseModelService } from './base/base-modelservice';

@Injectable()
export class NewsService extends BaseModelService<News> {

    constructor(errorService: ErrorService, parseService: ParseService) {
        super(errorService, parseService, News);
    }

    public get(): Promise<Array<News>> {
        return new Promise<Array<News>>((resolve, reject) => {
            const query = new Parse.Query(News);
            query.find().then(newsList => resolve(newsList), error => this.errorService.handleParseErrors(error));
        });
    }
}
