import { Injectable } from '@angular/core';
import { BaseModelService } from './base/base-modelservice';
import { ErrorService, ParseService, Parse } from '../services/index';
import { Course, PageInfo } from '../models/index';

@Injectable()
export class CourseService extends BaseModelService<Course> {

    constructor(errorService: ErrorService, parseService: ParseService) {
        super(errorService, parseService, Course);
    }

    // @ts-ignore (todo: rename)
    public getByFilter(searchQuery?: string, pageInfo?: PageInfo): Promise<Array<Course>> {
        return new Promise<Array<Course>>((resolve, reject) => {
            let query = new Parse.Query(Course);
            if (pageInfo) {
                pageInfo.filterQuery = searchQuery;
                query = this.applyPageInfo(query, pageInfo, this.filterByAttributes);
            } else {
                query = this.applyFilter(query, searchQuery, this.filterByAttributes);
            }
            query.find().then(defibrillators => resolve(defibrillators), error => this.errorService.handleParseErrors(error));
        });
    }

    private filterByAttributes(query: Parse.Query<Course>, filterQuery: string): Parse.Query<Course> {
        const queries = new Array<Parse.Query<Course>>();
        ['organizer', 'city', 'name'].forEach((attribute) => {
            const orQuery = new Parse.Query(Course);
            orQuery.matches(attribute, new RegExp(filterQuery), 'i');
            queries.push(orQuery);
        });
        return Parse.Query.or<Course>(...queries);
    }
}
