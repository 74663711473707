import { BaseModel, Parse } from './base/index';
import { EmergencyState } from './emergency-state';
import { User } from './user';

export class Tracer extends BaseModel {
    public static PARSE_CLASSNAME = 'Tracer';

    private _type: string;
    private _message: string;
    private _emergencyState: EmergencyState;
    private _user: User;

    constructor() {
        super(Tracer.PARSE_CLASSNAME);
    }

    public get type(): string {
        return this._type;
    }

    public set type(value: string) {
        this._type = value;
    }

    public get message(): string {
        return this._message;
    }

    public set message(value: string) {
        this._message = value;
    }

    public get emergencyState(): EmergencyState {
        return this._emergencyState;
    }

    public set emergencyState(value: EmergencyState) {
        this._emergencyState = value;
    }

    public get user(): User {
        return this._user;
    }

    public set user(value: User) {
        this._user = value;
    }

    
}

BaseModel.registerClass(Tracer, Tracer.PARSE_CLASSNAME);
