import { Injectable } from '@angular/core';
import { ErrorService, ParseService, Parse, Subscription } from '../services/index';
import { Emergency, EmergencyState, EmergencyStateEnum, PageInfo, RolePrivilegeEnum, User } from '../models/index';
import { BaseModelService } from './base/base-modelservice';
import AuthenticationService from 'app/data/app/services/authentication.service';

@Injectable()
export class EmergencyStateService extends BaseModelService<EmergencyState> {

  constructor(protected errorService: ErrorService, protected parseService: ParseService, private authenticationService: AuthenticationService) {
    super(errorService, parseService, EmergencyState);
  }

  public getByEmergency(emergency: Emergency, greaterThanOrEqualState?: number): Promise<Array<EmergencyState>> {
    return new Promise<Array<EmergencyState>>((resolve, reject) => {
      const query = this.createQuery();
      query.equalTo('emergencyRelation', emergency);
      if (greaterThanOrEqualState) {
        query.greaterThanOrEqualTo('state', greaterThanOrEqualState);
      }
      query.include('userRelation');
      query.include('protocolRelation');
      query.limit(99999999);
      query.find().then(emergencyStates => resolve(emergencyStates), error => this.errorService.handleParseErrors(error));
    });
  }

  public subByEmergency(emergency: Emergency): Promise<Subscription<EmergencyState>> {
    return new Promise<Subscription<EmergencyState>>((resolve, reject) => {
      const query = this.createQuery();
      query.equalTo('emergencyRelation', emergency);
      query.include('userRelation');
      this.parseService.subscribe<EmergencyState>(query).then(subscription => resolve(subscription));
    });
  }

  public getUncontactedFRCount(emergency: Emergency): Promise<number> {
    return new Promise<number>((resolve, reject) => {
      const query = this.createQuery();
      query.equalTo('emergencyRelation', emergency);
      query.greaterThan('state', EmergencyStateEnum.aborted);
      query.equalTo('contactedAt', null);

      query.count().then(totalCount => resolve(totalCount), error => this.errorService.handleParseErrors(error));
    });
  }

  public getInvolvedFRCount(emergency: Emergency): Promise<number> {
    return new Promise<number>((resolve, reject) => {
      const query = this.createQuery();
      query.equalTo('emergencyRelation', emergency);
      query.greaterThan('state', EmergencyStateEnum.aborted);
      query.count().then(totalCount => resolve(totalCount), error => this.errorService.handleParseErrors(error));
    });
  }

  public getNotifiedFR(emergency: Emergency): Promise<Array<EmergencyState>> {
    return new Promise<Array<EmergencyState>>((resolve, reject) => {
      const query = this.createQuery();
      query.equalTo('emergencyRelation', emergency);
      query.limit(99999999);
      query.find().then(emergencyStates => resolve(emergencyStates), error => this.errorService.handleParseErrors(error));
    });
  }

  public getEmergencyStatesWithOpenProtocols(user: User): Promise<Array<EmergencyState>> {
    return new Promise<Array<EmergencyState>>(async (resolve, reject) => {
      const query = this.createQuery(['protocolRelation']);
      const emergencyStateArray = new Array<EmergencyState>();
      query.equalTo('userRelation', user);
      query.greaterThanOrEqualTo('state', EmergencyStateEnum.aborted);
      query.limit(99999999);
      try {
        const emergencyStates = await query.find() as unknown as {results: Array<EmergencyState>, count: Number};
        if (emergencyStates.count.valueOf() > 0) {
          for (const emergencyState of emergencyStates.results) {
            if (emergencyState.protocolRelation == undefined) {
              emergencyStateArray.push(emergencyState);
            } else if (!emergencyState.protocolRelation.done) {
              emergencyStateArray.push(emergencyState);
            }
          }
        }
      } catch (e) {
        reject(e.message);
      }

      resolve(emergencyStateArray);
    });
  }

  private filterByAttributes(query: Parse.Query<EmergencyState>, filterQuery: string): Parse.Query<EmergencyState> {
    const queries = new Array<Parse.Query<EmergencyState>>();
    ['firstname', 'lastname', 'username'].forEach((attribute) => {
        const orQuery = new Parse.Query(EmergencyState);
        orQuery.matches(attribute, new RegExp(filterQuery), 'i');
        queries.push(orQuery);
    });
    return Parse.Query.or<EmergencyState>(...queries);
  }


  public getByFilter(searchQuery?: string, pageInfo?: PageInfo): Promise<Array<EmergencyState>> {
    return new Promise<Array<EmergencyState>>((resolve, reject) => {
        let query = this.createQuery();

        if (pageInfo) {
            pageInfo.filterQuery = searchQuery;
            query = this.applyPageInfo(query, pageInfo, this.filterByAttributes);
        } else {
            query = this.applyFilter(query, searchQuery, this.filterByAttributes);
        }

        query.include('userRelation');
        query.include('emergencyRelation');
        query.include('installationRelation');
        query.include('controlCenterRelation');
        query.limit(99999999);
        query.find().then(emergencyStates => resolve(emergencyStates), error => this.errorService.handleParseErrors(error));
    });
  }

  public createQuery(includes?: [keyof EmergencyState]): Parse.Query<EmergencyState> {
    const query = super.createQuery(includes);
    if (!ParseService.isParseServer()) {
      
      if (Parse.User.current().get('controlCenterRelation') != undefined) {
        query.exists('controlCenterRelation');
        query.equalTo('controlCenterRelation', Parse.User.current().get('controlCenterRelation'));
      }
      
      query.limit(999999);
    }
    return query;
  }

  
}
