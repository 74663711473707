import { BaseModel, Parse } from './base/index';

export enum LoggingTypeEnum {
    emailSent = 'EMAIL_SENT'
}

export class Logging extends BaseModel {
    public static PARSE_CLASSNAME = 'Logging';

    private _relatedClass: string;
    private _relatedId: string;
    private _type: LoggingTypeEnum;
    private _data: any;

    constructor() {
        super(Logging.PARSE_CLASSNAME);
    }

    public get relatedClass(): string {
        return this._relatedClass;
    }

    public get relatedId(): string {
        return this._relatedId;
    }

    public get type(): LoggingTypeEnum {
        return this._type;
    }

    public get data(): any {
        return this._data;
    }

    public set relatedClass(value: string) {
        this._relatedClass = value;
    }

    public set relatedId(value: string) {
        this._relatedId = value;
    }

    public set type(value: LoggingTypeEnum) {
        this._type = value;
    }

    public set data(value: any) {
        this._data = value;
    }

    public get mailTypeText(): string {
        switch (this.data.mailType) {
            case 'noDocumentEmail':
                return 'Kein Dokument';
            case 'noSignatureEmail':
                return 'Keine Unterschrift';
            case 'underageEmail':
                return 'Minderjährig';
            case 'welcomeExceptionEmail':
                return 'Ausnahme-Willkommensnachricht';
            case 'welcomeEmail':
                return 'Standard-Willkommensnachricht';
            case 'incompleteInformationEmail':
                return 'Unvollständige Informationen';
            case 'reminderEmail':
                return 'Erinnerung zur Nachweisaktualisierung';
            case 'deactivationEmail':
                return 'Deaktivierung aufgrund veraltetem Nachweis';
            case 'invalidDocumentEmail':
                return 'Fehlende Kenntnisse in Erste-Hilfe';
            case 'invalidInformationEmail':
                return 'Fehlerhafte oder fehlende Informationen';
            case 'oldDocumentEmail':
                return 'Veraltetes Dokument';
            case 'notReadableDocumentEmail':
                return 'Dokument nicht lesbar';
            case 'sendNewPinEmail':
                return 'Neue PIN versendet'
            default:
                return this.data.mailType;
        }
    }

}

BaseModel.registerClass(Logging, Logging.PARSE_CLASSNAME);
