import { Component } from '@angular/core';

@Component({
  // tslint:disable-next-line
  selector: 'body',
  templateUrl: 'app.component.html'
})
export class AppComponent {

  constructor() {
  }
}
