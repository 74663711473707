import { Injectable } from '@angular/core';
import { ErrorService, ParseService, Parse } from '../services/index';
import { Installation, SpontaneousOperation, TypeOperationEnum } from '../models/index';
import { BaseModelService } from './base/base-modelservice';
import { User } from '../models/user';
import { UserService } from './user.service';
import { Configuration } from '../models/configuration';
import { ServiceManager } from '../services/service-manager';

@Injectable()
export class InstallationService extends BaseModelService<Installation>  {

    private userService = ServiceManager.get(UserService);

    constructor(protected errorService: ErrorService, protected parseService: ParseService) {
        super(errorService, parseService, Installation);
    }

    public getLatestOfUser(user: User): Promise<Installation> {
        return new Promise<Installation>((resolve, reject) => {
            const query = this.createQuery();
            query.equalTo('userRelation', user);
            query.include('userRelation');
            query.descending('createdAt');
            query.first().then(installation => resolve(installation), error => this.errorService.handleParseErrors(error));
        });
    }

    public getByIdWithUser(installationId: string): Promise<Installation> {
        return new Promise<Installation>((resolve, reject) => {
            const query = this.createQuery();
            query.equalTo('objectId', installationId);
            query.include('userRelation');
            query.first().then(installation => resolve(installation), error => this.errorService.handleParseErrors(error));
        });
    }

/*     public getInstallationsFromUser(userId: string): Promise<Array<Installation>> {
        
        const user = new User();
        user.id = userId;
        const query = this.createQuery();
        query.equalTo('userRelation', user);
        query.include('userRelation');
        return query.find();
    } */

    public getInstallationsFromUser(userId: string): Promise<Array<Installation>> {
        return new Promise<Array<Installation>>((resolve, reject) => {
            const user = new User();
            user.id = userId;
            const query = this.createQuery();
            query.equalTo('userRelation', user);
            query.include('userRelation');
            query.find().then(installationList =>
                resolve(installationList), error => this.errorService.handleParseErrors(error));
        });
    }

    public getInstallationId(installationId: string): Promise<Installation> {
        return new Promise<Installation>((resolve, reject) => {
            const query = this.createQuery();
            query.include('userRelation');
            query.equalTo('installationId', installationId);
            query.first().then(installationList => resolve(installationList), error => this.errorService.handleParseErrors(error));
        });
    }

    public getAll(): Promise<Array<Installation>> {
        return new Promise<Array<Installation>>((resolve, reject) => {
            const query = this.createQuery();
            query.include('userRelation');
            query.limit(99999999);
            query.find().then(installationList => resolve(installationList), error => this.errorService.handleParseErrors(error));
        });
    }

    public getAllOrderedByCreationDesc(): Promise<Array<Installation>> {
        return new Promise<Array<Installation>>((resolve, reject) => {
            const query = this.createQuery();
            query.include('userRelation');
            query.descending('createdAt');
            query.limit(99999999);
            query.find().then(installationList => resolve(installationList), error => this.errorService.handleParseErrors(error));
        });
    }

    public getAllInRangeByConfig(location: Parse.GeoPoint, config: Configuration): Promise<Array<Installation>> {
        return new Promise<Array<Installation>>((resolve, reject) => {
            const userQuery = this.userService.createQuery()
                .equalTo('emailVerified', true)
                .equalTo('activated', true)
                .notEqualTo('dutyOff', true)
                .notContainedIn('dutyDays', [new Date().getDay()])
                .limit(9999999999);

            if (config.constraints) {
                if (config.constraints.professions) {
                    userQuery.notContainedIn('profession', config.constraints.professions);
                }
                if (config.constraints.qualifications) {
                    userQuery.notContainedIn('qualification', config.constraints.qualifications);
                }
            }

            const query = this.createQuery()
/*                 .ascending('deviceType')
                .addAscending('userRelation')
                .addDescending('createdAt') */
                .matchesQuery('userRelation', userQuery)
                .include('userRelation')
                .withinKilometers('location', location, (config.distance / 1000))
                .limit(999999999);

            query.find().then(installationList => resolve(installationList), error => this.errorService.handleParseErrors(error));
        });
    }

    public getInstallationQueryByOperationType(sop: SpontaneousOperation): Parse.Query<Installation> {
        const queries = new Array<Parse.Query<Installation>>();
        sop.typeOfOperation.forEach((type) => {
            const userQuery = this.userService.createQuery();
            if (type == TypeOperationEnum.ambulanceService) {
                userQuery.equalTo('sp_ambulance', true);
            } else if (type == TypeOperationEnum.elderlyCare) {
                userQuery.equalTo('sp_elderly', true);
            } else if (type == TypeOperationEnum.generalHelp) {
                userQuery.equalTo('sp_general', true);
            } else if (type == TypeOperationEnum.heavyTask) {
                userQuery.equalTo('sp_physical', true);
            }
            const orQuery = this.createQuery(['userRelation']).matchesQuery('userRelation', userQuery);
            queries.push(orQuery);
        });
        return Parse.Query.or<Installation>(...queries);
    }
}
