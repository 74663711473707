import { Injectable } from '@angular/core';
import { ErrorService, ParseService, Parse } from '../services/index';
import { Installation, User } from '../models/index';
import { BaseModelService } from './base/base-modelservice';
import { Session } from '../models/session';

@Injectable()
export class SessionService extends BaseModelService<Session> {

    constructor(errorService: ErrorService, parseService: ParseService) {
        super(errorService, parseService, Session);
    }

    public getByUser(user: User): Promise<Array<Session>> {
        return new Promise<Array<Session>>((resolve, reject) => {
            let query = this.createQuery();
            query.equalTo('user', user);
            query.limit(10000);
            query.find().then(sessions => resolve(sessions), error => this.errorService.handleParseErrors(error));
        });
    }

    public userHasValidSessions(user: User): Promise<number> {
        return new Promise<number>((resolve, reject) => {
            let query = this.createQuery();
            query.equalTo('user', user);
            query.greaterThan('expiresAt', new Date());
            query.count().then(sessions => resolve(sessions), error => this.errorService.handleParseErrors(error));
        });
    }

    public getSessionsfromUserByDate(user: User, date: Date, exclude?: string): Promise<Array<Session>> {
        return new Promise<Array<Session>>((resolve, reject) => {
            let query = this.createQuery();
            query.equalTo('user', user);
            if(exclude) {
                query.notEqualTo('user', exclude);
            }
            query.limit(10000);
            query.find().then(sessions => resolve(sessions), error => this.errorService.handleParseErrors(error));
        });
    }

    public getSessionsfromUser(user: User, installation: String): Promise<Array<Session>> {
        return new Promise<Array<Session>>((resolve, reject) => {
            let query = this.createQuery();
            query.equalTo('user', user);
            query.equalTo('installationId', installation);
            query.limit(500);
            query.find().then(sessions => resolve(sessions), error => this.errorService.handleParseErrors(error));
        });
    }

    public isSessionValid(user: User, installation: String): Promise<number> {
        return new Promise<number>((resolve, reject) => {
            let query = this.createQuery();
            query.equalTo('user', user);
            query.equalTo('installationId', installation);
            query.greaterThan('expiresAt', new Date());
            query.limit(500);
            query.count().then(sessions => resolve(sessions), error => this.errorService.handleParseErrors(error));
        });
    }

    public getValidInstallations(user: User): Promise<Array<Installation>> {
        return new Promise<Array<Installation>>(async (resolve, reject) => {
            const query = this.createQuery();
            query.equalTo('user', user);
            query.greaterThan('expiresAt', new Date());

            const query2 = new Parse.Query(Installation);
            query2.matchesKeyInQuery('installationId', 'installationId', query);

            query2.find().then(installations => resolve(installations), error => this.errorService.handleParseErrors(error));
        });
    }
}
